import React from 'react';

import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import BasicCard from '../basic';

import HealthExtraV1 from './v1/extra';
import HealthExtraV2 from './v2/extra';
import LoadingV2 from './v2/loading';
import PromotionContentV2 from './v2/promotion';

import './styles.scss';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

function useRequest({ onSubmit, startDate, endDate, dataloader }) {
	return useQuery({
		queryKey: ['card', 'health', { startDate, endDate }],
		queryFn: async ({ meta }) => {
			const response = await onSubmit?.('card:fetch', {
				type: 'health',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				dataloader: meta?.dataloader,
			});

			return response;
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate,

		meta: { dataloader },
	});
}

/** @type { ChildrenProps['config'] } */
const CONFIG = {
	onboarding: {
		required: ['erp', 'bank'],
		optional: 'both',
		loading: 'bank',
	},
	card: {
		content: {
			loading: {
				v2: (props) => <LoadingV2 {...props} />,
			},
			default: {
				view: {
					v1: (props) => (
						<div className="flex flex-col gap-2">
							<HealthExtraV1 {...props} />
						</div>
					),
					v2: (props) => (
						<div className="flex flex-col gap-2">
							<HealthExtraV2 {...props} />
						</div>
					),
				},
			},
			promotion: {
				view: {
					v2: (props) => (
						<div className="flex flex-col gap-2">
							<PromotionContentV2 {...props} />
						</div>
					),
				},
			},
			fetching: {
				view: {
					v2: (props) => (
						<div className="flex flex-col gap-2">
							<PromotionContentV2 {...props} />
						</div>
					),
				},
			},
			importing: {
				view: {
					v2: (props) => (
						<div className="flex flex-col gap-2">
							<PromotionContentV2 {...props} />
						</div>
					),
				},
			},
		},
	},
};

function usePromotionState(props) {
	const { useDefaultValue, query } = props;

	const defaultValue = useDefaultValue?.(props);
	const isEmpty = query?.data?.source?.score === -1;

	return React.useMemo(() => {
		if (isEmpty) {
			return { visible: true, variant: 'card' };
		}

		return defaultValue;
	}, [defaultValue, isEmpty]);
}

/** @type { React.FC<Props> } */
const HealthCard = React.memo(function HealthCard(props) {
	const { startDate, endDate, onSubmit, dataloader } = props;

	const query = useRequest({
		onSubmit,
		startDate,
		endDate,
		dataloader,
	});

	return (
		<BasicCard
			{...props}
			type="health"
			config={CONFIG}
			query={query}
			usePromotionState={usePromotionState}
		/>
	);
});

HealthCard.displayName = 'HealthCard';

HealthCard.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),

	dataloader: PropTypes.object,
};

export default HealthCard;
