import React, { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useQueries } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import useSearchQuery from '@asteria/utils-hooks/useSearchQuery';

function useInvoiceFilters() {
	const search = useSelector(InvoiceStore.selectors.search);

	const statuses = useSelector(
		(store) =>
			InvoiceStore.selectors
				.filters(store, {
					type: 'SERVICE:STATUS',
				})
				.map(({ value }) => value),
		(a, b) => isEqual(a, b),
	);

	return useMemo(() => {
		if (statuses?.length && !statuses.includes('ERROR')) {
			return { services: { status: statuses } };
		}

		if (statuses.includes('ERROR')) {
			return { errors: true };
		}

		if (search?.length) {
			return null;
		}

		return { services: { status: null } };
	}, [search?.length, statuses]);
}

export function useData({ type, onSubmit, enabled = true }) {
	const invoiceFilters = useInvoiceFilters();

	return useQueries({
		queries: [
			{
				queryKey: [
					'invoices',
					'table',
					'invoices',
					{ filters: invoiceFilters },
				],
				queryFn: async () =>
					onSubmit?.('invoices:available', {
						filters: invoiceFilters,
						pageFilters: { first: 1_000 },
						changes: true,
						withPagination: true,
					}),

				refetchOnMount: true,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				placeholderData: [],

				enabled: enabled && type === 'invoices',
			},
			{
				queryKey: ['invoices', 'table', 'prints'],
				queryFn: async () =>
					onSubmit?.('invoice-layout:list', {
						skipDispatch: true,
						skipInvalidate: true,

						pageFilters: { first: 500 },
						fields: `
							_id id createdAt updatedAt status pdfUri
							errors { code message }
							meta { pages }
							invoices {
								_id id createdAt updatedAt
								meta { invoiceNumber clientNumber }
								sums { original { total currency } }
								errors { code message path }
								discard: services(filters: { status: ["DISCARD"] }) {
									id serviceId status createdAt updatedAt
									invoices { invoiceId status errors }
								}
							}
						`,
						filters: {
							status: [
								'ERROR',
								'IGNORED',
								'LOADING',
								'PENDING',
								'PROCESSED',
								'UNREAD',
							],
						},
					}),

				refetchOnMount: true,
				refetchOnReconnect: false,
				refetchOnWindowFocus: false,

				placeholderData: [],

				enabled: enabled && type === 'prints',
			},
		],
	});
}

/**
 * @returns { [ string, (value: string) => void ] }
 */
export function useType() {
	const dispatch = useDispatch();

	const [searchQuery, updateSearchQuery] = useSearchQuery();

	const type = searchQuery?.view ?? 'invoices';
	const setType = React.useCallback(
		(value) => updateSearchQuery('view', value),
		[updateSearchQuery],
	);

	React.useLayoutEffect(() => {
		return () => {
			setType(null);
		};
	}, [setType]);

	React.useLayoutEffect(() => {
		return () => {
			dispatch(
				InvoiceStore.filter([
					{ type: 'CLIENT', value: null },
					{ type: 'CURRENCY', value: null },
					{ type: 'SERVICE:STATUS', value: null },
					{ type: 'TYPE', value: null },
				]),
			);
		};
	}, [dispatch, type]);

	return [type, setType];
}
