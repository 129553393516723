import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Chip from '@asteria/component-chip';
import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';

const BetaSign = ({ action = 'none', className = '', ...props }) => {
	const dispatch = useDispatch();

	const handleClick = React.useCallback(() => {
		if (action === 'modal') {
			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.BETA_INFO,
				}),
			);
		}
	}, [dispatch, action]);

	return (
		<FeatureFlag feature="beta-sign">
			<Chip
				onClick={action === 'modal' ? handleClick : undefined}
				label={TranslationService.getV2('beta.badge.text', {
					default: 'BETA',
				})}
				icon={action === 'modal' ? 'info-circle' : undefined}
				size="sm"
				className={`absolute top-4 right-4 uppercase bg-[#E9E9E9] rounded-full ${className}`}
				{...props}
			/>
		</FeatureFlag>
	);
};

BetaSign.propTypes = {
	/** Action to perform when clicked. Use 'modal' to show beta info modal */
	action: PropTypes.oneOf(['none', 'modal']),
	/** Additional CSS classes */
	className: PropTypes.string,
};

BetaSign.defaultProps = {
	action: 'none',
	className: '',
};

export default BetaSign;
