import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { ConfirmationModal } from '@asteria/component-modal';

/**
 * @typedef Print
 * @property { string } id
 * @property { string } status
 * @property { string } createdAt
 *
 * @typedef Props
 * @property { Print } print
 * @property { boolean } open
 * @property { import('react').MouseEventHandler } onClose
 * @property { import('react').MouseEventHandler } onSuccess
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 */

/** @type { React.FC<Props> } */
const PrintRemoveModal = React.memo(function PrintRemoveModal(props) {
	const { open, print, onClose, onSubmit, onSuccess } = props;

	const id = print?._id ?? print?.id;

	const removeMutation = useMutation({
		mutationFn: async () => onSubmit?.('print:remove', { id }),
		onSuccess: onSuccess ?? onClose,
	});

	const options = React.useMemo(
		() => ({
			postfix: { from: 'print-remove', status: print?.status },
			data: { id, data: print },
		}),
		[id, print],
	);

	return (
		<ConfirmationModal
			open={open}
			onClose={onClose}
			onSubmit={removeMutation.mutateAsync}
			options={options}
		/>
	);
});

PrintRemoveModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	print: PropTypes.object,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onSuccess: PropTypes.func,
};

export default PrintRemoveModal;
