import React from 'react';

import { useFormContext, useFormState } from 'react-hook-form';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import BetaSign from '@asteria/component-beta-sign';
import Form, { Wrapper as FormWrapper, Input } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { AuthContext } from '../context';
import Languages from '../languages';
import { defaultReducer, formatAuthResponse, formatError } from '../utils';

const RecoverPageContent = React.memo(function RecoverPageContent({
	loading,
	error,
	back,
}) {
	const { logo } = React.useContext(AuthContext);

	const { trigger, getValues, setError } = useFormContext();
	const { errors } = useFormState({ name: ['password1', 'password2'] });

	const onChange = React.useCallback(async () => {
		const [password1, password2] = getValues(['password1', 'password2']);

		if (password1 && password2) {
			await trigger(['password1', 'password2']);

			if (password1 !== password2) {
				setError('password2', {
					type: 'error',
					message: TranslationService.get([
						'signup.password.error.mismatch',
						'auth.signup.password.error.mismatch',
					]),
				});
			}
		}
	}, [getValues, setError, trigger]);

	return (
		<Wrapper scroll>
			<Header logo={logo} onBack={back} verticalAlign="center">
				{TranslationService.get([
					'auth.title',
					`auth.password.title`,
					`auth.password.reset.title`,
				])}
			</Header>
			<Content scroll>
				{error ? (
					<Alert level="error">
						<Text>{error}</Text>
					</Alert>
				) : null}
				<FormWrapper scroll>
					<Content scroll>
						<Input
							key="password1"
							type="password"
							name="password1"
							placeholder={TranslationService.get(
								'auth.password.reset.password1.placeholder',
							)}
							label={TranslationService.get(
								'auth.password.reset.password1.label',
							)}
							required
							onChange={onChange}
							autoFocus
						/>
						<Input
							key="password2"
							type="password"
							name="password2"
							placeholder={TranslationService.get(
								'auth.password.reset.password2.placeholder',
							)}
							label={TranslationService.get(
								'auth.password.reset.password2.label',
							)}
							required
							onChange={onChange}
						/>
					</Content>
				</FormWrapper>
			</Content>
			<Footer>
				<FooterSection position="first">
					<Button
						label={TranslationService.get([
							'action.back',
							`auth.password.action.back`,
							`auth.password.forgot.action.back`,
							`auth.password.reset.action.back`,
						])}
						variant="secondary"
						onClick={back}
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						type="submit"
						label={TranslationService.get([
							'action.submit',
							`auth.password.action.submit`,
							`auth.password.forgot.action.submit`,
							`auth.password.reset.action.submit`,
						])}
						variant="primary"
						loading={loading}
						disabled={loading || !!Object.keys(errors).length}
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

RecoverPageContent.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.string,
	step: PropTypes.string,
	back: PropTypes.func,
};

const RecoverPage = React.memo((props) => {
	const { className } = props;

	const {
		onAction,
		onSubmit,
		partnerId: defaultPartnerId,
		languages,
		onLanguageChange,
	} = React.useContext(AuthContext);

	const [search] = useSearchParams();
	const navigate = useNavigate();

	const [{ loading, error, step }, update] = React.useReducer(
		defaultReducer,
		{ loading: false, error: null, step: null, data: null },
	);

	const back = React.useCallback(() => {
		search.delete('code');
		search.delete('partnerId');

		return navigate(
			['/auth/login', search.toString()].filter(Boolean).join('?'),
		);
	}, [navigate, search]);

	const handleSubmit = React.useCallback(
		async (form) => {
			if (form?.password1 !== form?.password2) {
				return update({
					type: 'FAILURE',
					payload: TranslationService.get(
						'signup.password.error.mismatch',
					),
				});
			}

			update({ type: 'START' });

			let response;

			try {
				response = await onSubmit?.('auth:password-recover', form);
			} catch (err) {
				return update({
					type: 'FAILURE',
					payload: formatError({ error: err, form }),
				});
			}

			const { accessToken, refreshToken } = formatAuthResponse(response);

			if (accessToken) {
				onAction?.('auth:token', {
					accessToken,
					refreshToken,
					valid: true,
				});
			}

			update({ type: 'STOP' });

			search.delete('code');
			search.delete('partnerId');

			return navigate(['/', search.toString()].filter(Boolean).join('?'));
		},
		[navigate, onAction, onSubmit, search],
	);

	const partnerId = search.get('partnerId') ?? defaultPartnerId;
	const secret = search.get('code');

	const defaultValues = React.useMemo(
		() => ({ partnerId: partnerId, secret: secret }),
		[partnerId, secret],
	);

	if (!partnerId || !secret) {
		return (
			<Navigate
				to={['/auth/login', search.toString()]
					.filter(Boolean)
					.join('?')}
				replace
			/>
		);
	}

	return (
		<div
			className={cn(
				'asteria-view__auth-content',
				'asteria--variant-recover',
				className,
			)}
		>
			<BetaSign />

			<Form onSubmit={handleSubmit} defaultValues={defaultValues}>
				<RecoverPageContent
					loading={loading}
					error={error}
					step={step}
					back={back}
				/>
			</Form>

			{languages ? <Languages onChange={onLanguageChange} /> : null}
		</div>
	);
});

RecoverPage.displayName = 'RecoverPage';

RecoverPage.propTypes = {
	className: PropTypes.string,
};

export default RecoverPage;
