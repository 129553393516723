import { formatISO, isAfter, isBefore, subMonths } from 'date-fns';

import { TranslationService } from '@asteria/language';
import { parseDate } from '@asteria/utils-funcs/normalize';

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/**
 * @param { Props & { dataloader?: unknown } } options
 */
export function getRequest({
	startDate,
	endDate,
	onSubmit,
	dataloader,
	enabled = true,
}) {
	return {
		queryKey: ['card', 'account', { startDate, endDate }],
		queryFn: async ({ meta }) => {
			return onSubmit?.('card:fetch', {
				type: 'account',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subMonths(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subMonths(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
				dataloader: meta?.dataloader,
			});
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate && enabled,

		meta: { dataloader },
	};
}

export function getDateRange({ startDate, endDate }) {
	if (!startDate || !endDate) {
		return null;
	}

	const startDateFmt = parseDate(startDate);
	const endDateFmt = parseDate(endDate);

	const now = new Date();

	if (isAfter(now, startDateFmt) && isBefore(now, endDateFmt)) {
		return 'current';
	}

	if (isAfter(now, endDateFmt)) {
		return 'past';
	}

	return 'future';
}

export function formatData(data, dates) {
	const paid = data?.source?.PAID?.total ?? 0;
	const forecast = data?.source?.FORECAST?.total ?? 0;

	const type = getDateRange(dates);

	return {
		value: type !== 'future' ? paid : null,
		forecast:
			type === 'future' ? forecast : type === 'current' ? paid : null,
		credit: data?.source?.CREDIT?.total ?? 0,

		area:
			type === 'future'
				? [
						data?.source?.FORECAST?.min ?? forecast,
						data?.source?.FORECAST?.max ?? forecast,
				  ]
				: null,

		label: TranslationService.getV2(['card.content.chart.x-axis.label'], {
			data: { date: dates?.startDate ?? dates?.endDate },
			default: '{{ date | date:"MMMM" | capitalize }}',
		}),

		data: data?.source,
		dates: dates,
	};
}
