import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/**
 * @typedef { import('../../../basic/types').ChildrenProps } ChildrenProps
 *
 * @typedef Props
 * @property { React.MouseEventHandler } onClick
 * @property { string } section
 */

/** @type { React.FC<Pick<ChildrenProps, 'translationOptions' | 'loading'> & Props> } */
const HighlightClientView = React.memo(function HighlightClientView(props) {
	const { section, translationOptions, loading, onClick } = props;

	const variant = translationOptions?.postfix?.variant;
	const statistics = translationOptions?.data?.source?.statistics?.[section];

	const label = TranslationService.getV2(['card.action'], {
		postfix: {
			type: 'client-details',
			variant: variant,
			action: `open-${section}`,
		},
		data: statistics,
	});

	return (
		<div
			className={cn(
				'border border-solid border-border-normal rounded p-4 flex gap-4 cursor-pointer',
				'asteria-component__card-section',
				'asteria--type-highlight',
			)}
			onClick={onClick}
		>
			<div
				className={cn(
					'h-6 w-6 grid place-content-center rounded',
					'hidden',
					'asteria--section-icon',
				)}
			>
				<Icon
					icon={section === 'best' ? 'thumbs-up' : 'thumbs-down'}
					size="sm"
				/>
			</div>
			<TextGroup>
				<TextGroup>
					<Translation
						translationKey={`card.content.client.${section}`}
						translationOptions={translationOptions}
						Component={Text}
						size="sm"
						loading={loading}
					/>
					<Button
						size="sm"
						variant="link"
						tooltip={label}
						label={label}
					/>
				</TextGroup>
				<Translation
					translationKey={`card.content.client.${section}.total`}
					translationOptions={translationOptions}
					Component={Title}
					size="xs"
					loading={loading}
				/>
			</TextGroup>
		</div>
	);
});

HighlightClientView.propTypes = {
	section: PropTypes.string,
	translationOptions: PropTypes.object,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
};

export default HighlightClientView;
