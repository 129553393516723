import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { formatISO, startOfDay } from 'date-fns';
import { merge } from 'lodash-es';
import PropTypes from 'prop-types';

import AsteriaCore from '@asteria/core';

import { Setup2FA } from '@asteria/view-auth';

import { Title } from '@asteria/component-core/typography';

import Alert from '@asteria/component-alert';
import DevToolsButton from '@asteria/component-devtools';
import { SnackbarWrapper } from '@asteria/component-snackbar';

import * as AppStore from '@asteria/datalayer/stores/app';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';
import * as ModalStore from '@asteria/datalayer/stores/modals';
import * as SnackbarStore from '@asteria/datalayer/stores/snackbar';
import * as TourStore from '@asteria/datalayer/stores/tour';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useBackgroundLocation } from '@asteria/utils-hooks/navigation';
import useConfig from '@asteria/utils-hooks/useConfig';
import { hooks as BaseHooks } from '@asteria/widget-base';

import * as ClientsAPI from '../api/clients';
import * as CompanyAPI from '../api/companies';
import * as FeedbackAPI from '../api/feedback';
import * as IntegrationsAPI from '../api/integrations';
import * as InvoiceLayoutAPI from '../api/invoice-layouts';
import * as InvoiceAPI from '../api/invoices';
import * as ReportAPI from '../api/reports';
import * as SupportAPI from '../api/support';
import * as UserAPI from '../api/user';
import { useCompanyVersionQuery } from '../components/CompanyVersion';
import PrintDialog from '../components/prints/dialog';

import Breadcrumbs from './Breadcrumbs';
import LayoutFooter from './Footer';
import LayoutHeader from './Header';
import ModalCollection from './ModalCollection';
import TourLogic from './Tour';
import Updater from './Updater';
import LayoutContext, { MenuContext } from './context';

import './styles.scss';

const LiveChat = React.memo(() => {
	React.useLayoutEffect(() => {
		const selectors = getComputedStyle(
			document.documentElement,
		).getPropertyValue('--asteria-selectors');

		const node = document.querySelector(selectors);

		const primaryColor = getComputedStyle(node).getPropertyValue(
			'--color-groove-button',
		);

		if (window.groove) {
			window.groove.widget = window.groove.createWidget();
			window.groove.widget.updateSettings({
				primaryColor: primaryColor,
				enabled: false,
			});

			window.groove.widget.init(
				'03bd436c-2664-4d62-82af-9e7bf1035820',
				{},
			);
		}
	}, []);

	return null;
});

LiveChat.displayName = 'LiveChat';

const Preload = React.memo(() => {
	const integrations = useConfig('integrations');
	const virtualPrinter = useConfig(
		'pages.onboarding.box.v-print.placeholder',
		{ deep: true },
	);
	const support = useConfig('pages.support.form.placeholder', { deep: true });
	const batches = useConfig('pages.invoices.batch', { deep: true });

	const URLs = React.useMemo(() => {
		return []
			.concat(integrations)
			.concat(virtualPrinter)
			.concat(support)
			.concat(batches)
			.flatMap((integration) =>
				Object.values(AsteriaCore.utils.flatObjectStrict(integration))
					.map((value) => {
						if (value?.startsWith?.('url(')) {
							return value
								.match(/\(.+?\)/g)?.[0]
								?.slice?.(1, -1)
								?.replace?.(/'/g, '');
						}

						return value;
					})
					.filter((value) => value?.startsWith?.('http'))
					.filter((value) =>
						['.png', '.gif', '.jpg', '.jpeg'].some((ext) =>
							value?.endsWith?.(ext),
						),
					),
			);
	}, [batches, integrations, support, virtualPrinter]);

	React.useEffect(() => {
		function formatID(url) {
			return url.pathname
				.split('.')[0]
				.split('/')
				.filter(Boolean)
				.slice(2)
				.join('-');
		}

		for (const url of URLs) {
			const id = formatID(new URL(url));

			const node = document.querySelector(`link#${id}`);

			if (!node) {
				const node = document.createElement('link');
				node.id = id;
				node.rel = 'preload';
				node.href = url;
				node.as = 'image';

				document.head.appendChild(node);
			}
		}
	}, [URLs]);

	return null;
});

Preload.displayName = 'Preload';

const LayoutAlert = () => {
	const [open, setOpen] = React.useState(true);

	const onDismiss = React.useCallback(() => {
		setOpen(false);
	}, []);

	if (
		!open ||
		!(
			TranslationService.hasKey(['freja.layout.alert.title']) ||
			TranslationService.hasKey(['freja.layout.alert.content'])
		)
	) {
		return null;
	}

	return (
		<Alert
			level="error"
			title={
				<Title size="xs">
					{TranslationService.get(['freja.layout.alert.title'])}
				</Title>
			}
			onDismiss={onDismiss}
		>
			{TranslationService.get(['freja.layout.alert.content'])}
		</Alert>
	);
};

function formatPage(pathname) {
	if (pathname === '/') {
		return 'home';
	}

	if (pathname.startsWith('/onboarding')) {
		return 'onboarding';
	}

	if (pathname.startsWith('/invoices')) {
		if (pathname.includes('review')) {
			return 'invoices-review';
		}

		if (pathname.includes('batch')) {
			return 'invoices-batch';
		}

		return 'invoices';
	}

	if (pathname.startsWith('/settings')) {
		return 'settings';
	}

	if (pathname.startsWith('/support')) {
		return 'support';
	}

	if (pathname.startsWith('/guide')) {
		return 'guide';
	}

	if (pathname.startsWith('/faq')) {
		return 'faq';
	}

	return null;
}

const Layout = (props) => {
	const { className, debug, auth } = props;

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const store = useStore();

	const accessToken = useSelector(AppStore.selectors.accessToken);

	const onAuthAction = BaseHooks.auth.useAction();
	const onAuthSubmit = BaseHooks.auth.useSubmit();

	const isPxR = useCompanyVersionQuery({ eq: 2 });
	const queryClient = useQueryClient();

	const location = useLocation();
	const { search, pathname } = location;

	const pathnameRef = React.useRef(pathname);

	React.useEffect(() => {
		if (!pathname?.includes?.('/onboarding/erp')) {
			pathnameRef.current = pathname;
		}
	}, [pathname]);

	const [isMenuOpen, setMenuOpen] = React.useState(false);

	const handleMenuOpen = React.useCallback(() => {
		setMenuOpen(true);
	}, []);

	const handleMenuClose = React.useCallback(() => {
		setMenuOpen(false);
	}, []);

	const handleMenuToggle = React.useCallback(() => {
		setMenuOpen((value) => !value);
	}, []);

	const onAction = React.useCallback(
		async (action, data) => {
			if (debug) {
				/* eslint-disable */
				console.group('onAction');
				console.log('action', action);
				console.log('data', data);
				console.groupEnd();
				/* eslint-enable */
			}

			if (action === 'snackbar:open') {
				SnackbarStore.show(dispatch, data);

				return;
			}

			if (action === 'user:settings:save') {
				const { service: { serviceId, ...service } = {}, ...input } =
					data;

				const user = store.getState()?.app?.user;
				const company = store.getState()?.app?.company;
				const partner = store.getState()?.app?.partner;

				const userId = user?._id ?? user?.id ?? null;
				const companyId = company?._id ?? company?.id ?? null;
				const partnerId = partner?._id ?? partner?.id ?? null;

				const response = await UserAPI.update({
					accessToken: accessToken,
					id: userId,
					input: input,
					dispatch,
				});

				if (serviceId) {
					await CompanyAPI.updateService(
						{
							accessToken: accessToken,
							dispatch: dispatch,
							id: serviceId,
							input: {
								...service,
								status: 'MODIFIED',
								companies: { companyId: companyId },
							},
						},
						{ queryClient: queryClient, store: store },
					);
				} else {
					await CompanyAPI.addService(
						{
							accessToken: accessToken,
							dispatch: dispatch,
							id: partnerId,
							input: {
								...service,
								status: 'MODIFIED',
								companies: { companyId: companyId },
							},
						},
						{ queryClient: queryClient, store: store },
					);
				}

				return response;
			}

			if (action === 'user:remove') {
				const user = store.getState()?.app?.user;
				const userId = user?.id ?? null;

				return UserAPI.remove(
					{
						accessToken: accessToken,
						id: userId,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'updateFeedback') {
				return FeedbackAPI.create(
					{
						accessToken: accessToken,
						feedback: data,
						dispatch: dispatch,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'tour:start') {
				dispatch(TourStore.show({ type: data }));
				return;
			}

			if (action === 'tour:end') {
				dispatch(TourStore.hide());
				return;
			}

			if (action === 'connection:open') {
				const { type, key } = data;

				return onAction?.('go', `/onboarding/${type}/${key}`);
			}

			if (action === 'faq:details') {
				const section = data?.section;
				const id = data?.id;

				return onAction?.('go', `/faq/${section}/${id}`);
			}

			if (action === 'guide:details') {
				return onAction?.('go', `/guide/${data}`);
			}

			if (action === 'go') {
				if (data === '/profile') {
					dispatch(
						ModalStore.open({
							type: ModalStore.MODAL_WINDOWS.AuthProfileChange,
							data: { accessToken: accessToken },
						}),
					);

					return;
				}

				const selected = InvoiceStore.selectors.selected(
					store.getState(),
				);

				const next = data?.path ?? data;

				if (
					selected.length &&
					next !== '/invoices/review' &&
					next !== '/invoices'
				) {
					return dispatch(
						ModalStore.open({
							type: 'INVOICE_SELECTED_CONFIRM',
							data: { next: data },
							skipValidation: true,
						}),
					);
				}

				if (next !== '/invoices/review' && next !== '/invoices') {
					dispatch(InvoiceStore.filter({ type: null, value: null }));
				}

				const state = { from: pathnameRef.current, ...data?.state };

				if (
					next?.startsWith?.('/onboarding') &&
					next !== '/onboarding'
				) {
					state.backgroundLocation = location;
				}

				let nextSearch = search;

				if (data?.search) {
					nextSearch = new URLSearchParams(search);

					if (typeof data?.search === 'object') {
						Object.entries(data?.search).forEach(([key, value]) =>
							nextSearch.set(key, value),
						);
					}

					nextSearch = '?' + nextSearch.toString();
				}

				navigate(
					typeof next === 'string' ? `${next}${nextSearch}` : next,
					{ state: state, replace: data?.replace },
				);
			}

			if (action === 'box:integration:missing:submit') {
				const title = data?.integration;

				IntegrationsAPI.missing(
					{
						accessToken: accessToken,
						input: { title: title },
					},
					{ queryClient: queryClient, store: store },
				);

				SnackbarStore.show(dispatch, {
					title: 'snackbar.onboarding.missing.success.title',
					content: 'snackbar.onboarding.missing.success.content',
					type: 'onboarding.missing',
					variant: 'success',
					icon: 'check',
					hideActions: true,
					data: { title: title },
				});

				return;
			}

			if (action === 'invoices:cancel') {
				dispatch(InvoiceStore.select([]));
			}

			if (action === 'invoices:revert') {
				const selected = InvoiceStore.selectors.selected(
					store.getState(),
				);

				await onSubmit?.('invoice:revert', {
					id: selected.map((object) => object?._id ?? object.id),
				});

				dispatch(InvoiceStore.select([]));

				SnackbarStore.show(dispatch, {
					title: 'snackbar.invoices.revert.success.title',
					content: 'snackbar.invoices.revert.success.content',
					type: 'invoices.revert',
					variant: 'success',
					icon: 'check',
					hideActions: true,
					data: { objects: selected },
				});

				await queryClient.invalidateQueries({
					predicate: (query) =>
						[]
							.concat(query.queryKey)
							.some((key) => key === 'invoices'),
				});
			}

			if (action === 'invoices:discard') {
				const selected = InvoiceStore.selectors.selected(
					store.getState(),
				);

				await onSubmit?.('invoice:ignore', {
					id: selected.map((object) => object?._id ?? object.id),
				});

				dispatch(InvoiceStore.select([]));

				SnackbarStore.show(dispatch, {
					title: 'snackbar.invoices.discard.success.title',
					content: 'snackbar.invoices.discard.success.content',
					type: 'invoices.discard',
					variant: 'success',
					icon: 'check',
					hideActions: true,
					data: { objects: selected },
				});

				await queryClient.invalidateQueries({
					predicate: (query) =>
						[]
							.concat(query.queryKey)
							.some((key) => key === 'invoices'),
				});
			}

			if (action === 'invoices:review') {
				return onAction?.('go', '/invoices/review');
			}

			if (action === 'invoices:approve') {
				const partner = store.getState()?.app?.partner;

				const lending = AppStore.selectors.company(store.getState())
					?.service?.data?.lending;

				const selected = InvoiceStore.selectors.selected(
					store.getState(),
				);

				let status = 'SENT';

				if (
					['not-receivable', 'Inte fakturabelåning'].includes(
						lending,
					) ||
					isPxR
				) {
					status = 'COMPLETED';
				}

				const batch = await InvoiceAPI.approve(
					{
						accessToken: accessToken,
						invoices: selected.map((object) => ({
							invoiceId: object?._id ?? object.id,
							data: data[object?._id ?? object.id] ?? {},
						})),
						serviceId: partner?.id,
						dispatch: dispatch,
						status: status,
					},
					{ queryClient: queryClient, store: store },
				);

				if (batch) {
					dispatch(InvoiceStore.select([]));

					SnackbarStore.show(dispatch, {
						title: 'snackbar.invoices.approve.success.title',
						content: 'snackbar.invoices.approve.success.content',
						type: 'invoices.approve',
						variant: 'success',
						icon: 'check',
						hideActions: true,
						data: { objects: selected },
					});
				}

				await queryClient.invalidateQueries({
					predicate: (query) => {
						return []
							.concat(query.queryKey)
							.some((key) => key === 'invoices');
					},
				});

				return batch;
			}

			if (action === 'invoices:batch:complete') {
				const partner = store.getState()?.app?.partner;

				const batch = await InvoiceAPI.completeBatch(
					{
						accessToken: accessToken,
						id: data?._id ?? data.id,
						serviceId: partner?.id,
						dispatch: dispatch,
					},
					{ queryClient: queryClient, store: store },
				);

				dispatch(InvoiceStore.select([]));

				SnackbarStore.show(dispatch, {
					title: 'snackbar.invoices.batch.complete.success.title',
					content: 'snackbar.invoices.batch.complete.success.content',
					type: 'invoices.batch.complete',
					variant: 'success',
					icon: 'check',
					hideActions: true,
					data: { batch: data },
				});

				await queryClient.invalidateQueries({
					predicate: (query) =>
						[]
							.concat(query.queryKey)
							.some((key) => key === 'invoices'),
				});

				return batch;
			}

			if (action === 'invoices:batch:remove') {
				const partner = store.getState()?.app?.partner;

				const response = await Promise.all(
					[].concat(data?._id ?? data.id).map((id) =>
						InvoiceAPI.removeBatch(
							{
								accessToken: accessToken,
								id: id,
								serviceId: partner?.id,
								dispatch: dispatch,
							},
							{ queryClient: queryClient, store: store },
						),
					),
				);

				const batch = response?.[0];

				dispatch(InvoiceStore.select([]));

				SnackbarStore.show(dispatch, {
					title: 'snackbar.invoices.batch.removed.success.title',
					content: 'snackbar.invoices.batch.removed.success.content',
					type: 'invoices.batch.removed',
					variant: 'success',
					icon: 'check',
					hideActions: true,
					data: { batch: data },
				});

				await queryClient.invalidateQueries({
					predicate: (query) =>
						[]
							.concat(query.queryKey)
							.some((key) => key === 'invoices'),
				});

				return batch;
			}

			if (action === 'stepper:click') {
				const batches = InvoiceStore.selectors.batches(
					store.getState(),
					{ status: 'SENT' },
				);

				const batchID = batches?.[0]?._id ?? batches?.[0]?.id;

				let path = null;

				if (data === 'connection') {
					path = '/onboarding';
				}

				if (data === 'layouts') {
					path = '/layouts';
				}

				if (data === 'invoices') {
					path = '/invoices';
				}

				if (data === 'review') {
					path = `/invoices/batch/${batchID}`;
				}

				if (path) {
					return onAction?.('go', path);
				}
			}

			if (action.startsWith('auth')) {
				return onAuthAction(action, data);
			}

			if (action === 'onboarding:missing:action') {
				if (data?.type === 'report') {
					return onAction?.('go', '/onboarding/missing');
				}

				if (data?.type === 'printer') {
					return onAction?.(
						'go',
						'/onboarding/erp/printer/configuration',
					);
				}
			}

			if (action === 'onboarding:fix') {
				const id = data?.id;

				return onAction?.('go', `/onboarding/${id}/status`);
			}

			if (action === 'print:open') {
				return dispatch(
					ModalStore.open({
						skipValidation: true,

						type: 'PRINT_DETAILS',
						data: { id: data?.id },
					}),
				);
			}

			if (action === 'action:fix' || action === 'action:error') {
				if (data?.type === 'client') {
					const id = data?.client?._id ?? data?.client?.id;

					return dispatch(
						ModalStore.open({
							type: ModalStore.MODAL_WINDOWS.ClientOverview,
							skipValidation: true,
							data: { _id: id },
						}),
					);
				}

				if (data?.type === 'invoice') {
					//
				}

				if (data?.type === 'invoice-error') {
					const id = data?.invoice?._id ?? data?.invoice?.id;

					return dispatch(
						ModalStore.open({
							type: 'INVOICE_DETAILS',
							data: {
								_id: id,
							},
							skipValidation: true,
						}),
					);
				}

				if (data?.type === 'batch') {
					return dispatch(
						ModalStore.open({
							type: 'BATCH_REVIEW',
							skipValidation: true,
							data: { id: data?.batch?.id },
						}),
					);
				}

				if (data?.type === 'integration') {
					return onAction?.('onboarding:fix', data?.integration);
				}

				if (data?.type === 'print') {
					return onAction?.('print:open', {
						id: data?.object?._id ?? data?.object?.id,
					});
				}
			}

			// if (action === 'action:error') {
			// 	if (data?.type === 'client') {
			// 		return dispatch(
			// 			ModalStore.open({
			// 				type: 'CLIENT_LIST',
			// 				skipValidation: true,
			// 			}),
			// 		);
			// 	}

			// 	if (data?.type === 'invoice') {
			// 		return onAction?.('go', '/invoices');
			// 	}

			// 	if (data?.type === 'invoice-error') {
			// 		return onAction?.('go', '/invoices');
			// 	}

			// 	if (data?.type === 'batch') {
			// 		return onAction?.('go', '/invoices');
			// 	}

			// 	if (data?.type === 'integration') {
			// 		return onAction?.('go', '/onboarding');
			// 	}

			// 	if (data?.type === 'print') {
			// 		dispatch(
			// 			InvoiceStore.filter({
			// 				type: 'SERVICE:STATUS',
			// 				value: 'ERROR',
			// 			}),
			// 		);

			// 		return onAction?.('go', {
			// 			path: '/invoices',
			// 			search: { view: 'prints' },
			// 			state: { from: 'actions' },
			// 		});
			// 	}
			// }

			if (action === 'invoice:open') {
				return dispatch(
					ModalStore.open({
						type: 'INVOICE_DETAILS',
						data: { _id: data },
						skipValidation: true,
					}),
				);
			}

			if (action === 'invoice:edit') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.InvoiceEdit,
						data: { id: data },
					}),
				);
			}

			if (action === 'print:dialog:action') {
				return onAction?.(
					'go',
					'/onboarding/erp/printer/configuration',
				);
			}

			if (action === 'server:error:action') {
				return onAction?.('go', '/support');
			}
		},
		[
			accessToken,
			debug,
			dispatch,
			isPxR,
			location,
			navigate,
			onAuthAction,
			queryClient,
			search,
			store,
		],
	);

	const onSubmit = React.useCallback(
		async (action, data) => {
			if (debug) {
				/* eslint-disable */
				console.group('onSubmit');
				console.log('action', action);
				console.log('data', data);
				console.groupEnd();
				/* eslint-enable */
			}

			if (action.startsWith('auth')) {
				return onAuthSubmit(action, data);
			}

			if (action === 'support') {
				return SupportAPI.report(
					{
						accessToken: accessToken,
						input: data,
						dispatch: dispatch,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'integrations:missing') {
				return IntegrationsAPI.missing(
					{
						accessToken: accessToken,
						input: data,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'batches:list') {
				const serviceId = store.getState()?.app?.partner?.id;

				return InvoiceAPI.batches(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						serviceId: serviceId,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'invoice-layout:list') {
				const response = await InvoiceLayoutAPI.fetch(
					{
						accessToken: accessToken,
						dispatch: dispatch,

						filters: data?.filters,
						pageFilters: data?.pageFilters,
						skipDispatch: data?.skipDispatch,
						skipPagination: data?.skipPagination,
						raw: data?.raw,
						fields: data?.fields,
					},
					{ queryClient: queryClient, store: store },
				);

				if (!data?.skipInvalidate) {
					await queryClient.invalidateQueries({
						predicate: (query) =>
							[]
								.concat(query.queryKey)
								.some((key) => key === 'layouts'),
					});
				}

				return response;
			}

			if (action === 'invoice-layout:count') {
				return InvoiceLayoutAPI.count(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						skipDispatch: data?.skipDispatch,
						filters: data?.filters,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'invoice-layout:unread') {
				return InvoiceLayoutAPI.unread(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						skipDispatch: data?.skipDispatch,
						filters: data?.filters,
						pageFilters: data?.pageFilters,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'invoice-layout:details') {
				return InvoiceLayoutAPI.details(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						id: data?.id,
						fields: data?.fields,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'message:send') {
				const response = await InvoiceLayoutAPI.sendMessage(
					{
						accessToken: accessToken,
						id: data?.id,
						message: data?.message,
						dispatch: dispatch,
					},
					{ queryClient: queryClient, store: store },
				);

				return response;
			}

			if (action === 'message:update') {
				const response = await InvoiceLayoutAPI.updateMessage(
					{
						accessToken: accessToken,
						layoutId: data?.layoutId,
						input: data?.input,
						dispatch: dispatch,
					},
					{ queryClient: queryClient, store: store },
				);

				await queryClient.invalidateQueries({
					predicate: (query) =>
						[]
							.concat(query.queryKey)
							.some((key) => key === 'layouts'),
				});

				return response;
			}

			if (action === 'integrations:list') {
				return IntegrationsAPI.fetch(
					{
						accessToken: accessToken,
						dispatch: dispatch,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'integrations:create') {
				const input = {
					createdAfter: formatISO(startOfDay(new Date()), {
						representation: 'date',
					}),
					entity: {
						customers: { disabled: false },
						'customer:invoices': { disabled: false },
						'customer:payments': { disabled: false },
						suppliers: { disabled: true },
						'supplier:invoices': { disabled: true },
						'supplier:payments': { disabled: true },
						'expenses:financial:years': { disabled: true },
						expenses: { disabled: true },
						articles: { disabled: true },
						orders: { disabled: true },
						company: { disabled: true },
						webhook: { disabled: true },
					},
				};

				if (Array.isArray(data)) {
					return Promise.all(
						data.map((data) =>
							IntegrationsAPI.create(
								{
									accessToken: accessToken,
									dispatch: dispatch,
									input: { ...data, ...input },
								},
								{ queryClient: queryClient, store: store },
							),
						),
					);
				}

				return IntegrationsAPI.create(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						input: { ...data, ...input },
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'integrations:delete') {
				return IntegrationsAPI.remove(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						_id: data.id,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'invoices:available') {
				const serviceId = store.getState()?.app?.partner?.id;

				return InvoiceAPI.available(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						serviceId: serviceId,
						options: data,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'invoices:details') {
				const serviceId = store.getState()?.app?.partner?.id;

				return InvoiceAPI.details(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						serviceId: serviceId,
						options: data,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'invoices:refresh') {
				return InvoiceAPI.refresh(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						timestamp: data?.timestamp,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'invoices:service:update') {
				const serviceId = store.getState()?.app?.partner?.id;

				const id = data?.id;

				const form =
					data?.data?.service?.invoice ?? data?.data?.service;

				const invoice = data?.data?._invoice;

				if (!invoice) {
					return;
				}

				const service = (invoice?.modifications ?? []).find(
					(object) => object?.serviceId === serviceId,
				);

				let response;

				if (!service) {
					response = await InvoiceAPI.addService(
						{
							accessToken: accessToken,
							dispatch: dispatch,
							serviceId: serviceId,
							input: {
								status: 'MODIFIED',
								invoices: { invoiceId: id, data: form },
							},
						},
						{ queryClient: queryClient, store: store },
					);
				} else {
					response = await InvoiceAPI.updateService(
						{
							accessToken: accessToken,
							dispatch: dispatch,
							id: service?.id,
							input: {
								status: 'MODIFIED',
								invoices: { invoiceId: id, data: form },
							},
						},
						{ queryClient: queryClient, store: store },
					);
				}

				await queryClient.invalidateQueries({
					predicate: (query) =>
						[]
							.concat(query.queryKey)
							.some((key) => key === 'invoices'),
				});

				return response;
			}

			if (action === 'clients:list') {
				return ClientsAPI.fetch(
					{ accessToken: accessToken, dispatch: dispatch },
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'clients:refresh') {
				return ClientsAPI.refresh(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						store: store,
						timestamp: data?.timestamp,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'clients:service:update') {
				const { id, data: form } = data;

				const serviceId = store.getState()?.app?.partner?.id;

				const client = AppStore.selectors.client(store.getState(), id);

				if (!client) {
					return;
				}

				const service = (client?.services ?? []).find(
					(service) =>
						service?.serviceId === serviceId &&
						service?.status === 'MODIFIED',
				);

				if (!service) {
					return ClientsAPI.addService(
						{
							accessToken: accessToken,
							dispatch: dispatch,
							serviceId: serviceId,
							input: {
								status: 'MODIFIED',
								clients: {
									clientId: id,
									data:
										form?.service?.client ?? form?.service,
								},
							},
						},
						{ queryClient: queryClient, store: store },
					);
				}

				return ClientsAPI.updateService(
					{
						accessToken: accessToken,
						dispatch: dispatch,
						id: service?.id,
						input: {
							status: 'MODIFIED',
							clients: {
								clientId: id,
								data: form?.service?.client ?? form?.service,
							},
						},
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'clients:service:remove') {
				const { id } = data;

				return ClientsAPI.removeService(
					{
						accessToken: accessToken,
						id: id,
						dispatch: dispatch,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'integrations:enable') {
				return IntegrationsAPI.enable(
					{
						accessToken: accessToken,
						_id: data.id,
						dispatch: dispatch,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'integrations:disable') {
				return IntegrationsAPI.disable(
					{
						accessToken: accessToken,
						_id: data.id,
						dispatch: dispatch,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'integrations:import') {
				return IntegrationsAPI.reimport(
					{
						accessToken: accessToken,
						_id: data.id,
						dispatch: dispatch,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'integrations:recreate') {
				return IntegrationsAPI.recreate(
					{
						accessToken: accessToken,
						input: {
							...data,
							entity: {
								customers: { disabled: false },
								'customer:invoices': { disabled: false },
								'customer:payments': { disabled: false },
								suppliers: { disabled: true },
								'supplier:invoices': { disabled: true },
								'supplier:payments': { disabled: true },
								'expenses:financial:years': { disabled: true },
								expenses: { disabled: true },
								articles: { disabled: true },
								orders: { disabled: true },
								company: { disabled: true },
								webhook: { disabled: true },
							},
						},
						dispatch: dispatch,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'invoice:remove') {
				const serviceId = store.getState()?.app?.partner?.id;

				const response = await InvoiceAPI.remove(
					{
						accessToken,
						id: data,
						dispatch,
						serviceId,
					},
					{ queryClient: queryClient, store: store },
				);

				await queryClient.invalidateQueries({
					predicate: (query) =>
						[]
							.concat(query.queryKey)
							.some((key) => key === 'invoices'),
				});

				return response;
			}

			if (action === 'user:settings:refresh') {
				return UserAPI.refreshSettings(
					{ accessToken, dispatch },
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'company:settings:flags:refresh') {
				return CompanyAPI.refreshSettingsFlags(
					{ accessToken, dispatch },
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'reports:list') {
				return ReportAPI.fetch(
					{ accessToken, dispatch, options: data },
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'onboarding:save') {
				if (data?.$id) {
					// eslint-disable-next-line no-unused-vars
					const { $id, type, key, ...rest } = data;

					return onSubmit?.(
						'integrations:recreate',
						merge(
							{},
							{ _id: $id, config: { connected: false } },
							{ ...rest },
						),
					);
				}

				return onSubmit?.('integrations:create', data);
			}

			if (action === 'company:clean') {
				const companyId = store.getState()?.app?.company?._id;

				return CompanyAPI.clean(
					{ accessToken: accessToken, id: companyId },
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'conversation:list') {
				return [];
			}

			if (action === 'onboarding:action:dismiss') {
				return IntegrationsAPI.updateAction({
					accessToken,
					dispatch,
					input: {
						integrationId:
							data?.integration?._id ?? data?.integration?.id,
						actionId: data?.action?._id,
						data: { status: 'CANCELED' },
					},
				});
			}

			if (action === 'onboarding:action') {
				const id = data?.integration?._id ?? data?.integration?.id;

				if (data?.action?.action === 'token.code.update') {
					onAction?.('go', `/onboarding/${id}`);
				}

				return IntegrationsAPI.updateAction({
					accessToken,
					dispatch,
					input: {
						integrationId: id,
						actionId: data?.action?._id,
						data: { status: 'APPROVED' },
					},
				});
			}

			if (action === 'print:dialog:close') {
				const now = new Date().toISOString();

				dispatch(
					AppStore.setCompanySettingsFlags({
						'freja:prints:dialog:timestamp': now,
					}),
				);

				dispatch(
					AppStore.setUserSettingsFlags({
						'freja:prints:dialog:timestamp': now,
					}),
				);

				const response = await Promise.allSettled([
					onSubmit?.('company:settings:flags:refresh'),
					onSubmit?.('user:settings:refresh'),
				]);

				// queryClient.invalidateQueries({
				// 	predicate: (query) => query?.queryKey?.includes?.('prints'),
				// });

				return response;
			}

			if (action === 'print:status:done') {
				const now = new Date().toISOString();

				dispatch(
					AppStore.setCompanySettingsFlags({
						'freja:prints:timestamp': now,
					}),
				);

				dispatch(
					AppStore.setUserSettingsFlags({
						'freja:prints:timestamp': now,
					}),
				);

				const response = await Promise.allSettled([
					onSubmit?.('company:settings:flags:refresh'),
					onSubmit?.('user:settings:refresh'),
				]);

				// queryClient.invalidateQueries({
				// 	predicate: (query) => query?.queryKey?.includes?.('prints'),
				// });

				return response;
			}

			if (action === 'print:remove') {
				const response = await InvoiceLayoutAPI.remove({
					accessToken,
					id: data?.id,
				});

				queryClient.invalidateQueries({
					predicate: (query) => query?.queryKey?.includes?.('prints'),
				});

				return response;
			}

			if (action === 'invoice:ignore') {
				const partner = store.getState()?.app?.partner;

				return InvoiceAPI.discard(
					{
						accessToken: accessToken,
						ids: data?.id ?? [],
						serviceId: partner?.id,
						dispatch: dispatch,
					},
					{ queryClient: queryClient, store: store },
				);
			}

			if (action === 'invoice:revert') {
				const partner = store.getState()?.app?.partner;

				return InvoiceAPI.unassignFromService({
					accessToken,
					id: data?.id,
					serviceId: partner?.id,
				});
			}

			if (action === 'print:invoices:ignore') {
				await onSubmit?.('invoice:ignore', { id: data?.invoices });

				return onSubmit?.('print:validate', { id: data?.id });
			}

			if (action === 'print:validate') {
				return InvoiceLayoutAPI.validate({ accessToken, id: data?.id });
			}
		},
		[
			accessToken,
			debug,
			dispatch,
			onAction,
			onAuthSubmit,
			queryClient,
			store,
		],
	);

	const onSnackbarAction = React.useCallback(() => {}, []);

	const onModalClose = React.useCallback(
		(event, options) => {
			dispatch(ModalStore.close(options));
		},
		[dispatch],
	);

	const ctx = React.useMemo(
		() => ({ onAction: onAction, onSubmit: onSubmit }),
		[onAction, onSubmit],
	);

	const menuCtx = React.useMemo(
		() => ({
			state: isMenuOpen,
			toggle: handleMenuToggle,
			open: handleMenuOpen,
			close: handleMenuClose,
		}),
		[handleMenuClose, handleMenuOpen, handleMenuToggle, isMenuOpen],
	);

	const backgroundLocation = useBackgroundLocation();

	const page = formatPage(backgroundLocation.pathname);

	if (auth) {
		return (
			<LayoutContext.Provider value={ctx}>
				<Outlet />
			</LayoutContext.Provider>
		);
	}

	return (
		<MenuContext.Provider value={menuCtx}>
			<div
				className={cn(
					'asteria-widget__layout',
					{
						'asteria-menu--state-open': isMenuOpen,
						'asteria-menu--state-closed': !isMenuOpen,
					},
					className,
				)}
			>
				<PrintDialog
					onAction={onAction}
					onSubmit={onSubmit}
					className={cn(
						'fixed bottom-0 right-0 left-0 w-full z-20 border border-solid border-border-normal',
						'tablet:border-0 tablet:left-auto tablet:bottom-4 tablet:right-8 tablet:w-[400px]',
					)}
				/>
				<DevToolsButton onAction={onAction} onSubmit={onSubmit} />
				<SnackbarWrapper onAction={onSnackbarAction} />
				<Updater onFetch={onSubmit} />
				<TourLogic onAction={onAction} />
				<ModalCollection
					onAction={onAction}
					onSubmit={onSubmit}
					onClose={onModalClose}
				/>
				<Setup2FA onAction={onAction} onSubmit={onSubmit} />
				<LiveChat />
				<Preload />

				<LayoutContext.Provider value={ctx}>
					<LayoutAlert />
					<LayoutHeader />
					<div
						className={cn('asteria-widget__layout-main', {
							[`asteria--page-${page}`]: page,
						})}
					>
						<Breadcrumbs />
						<Outlet />
					</div>
					<LayoutFooter />
				</LayoutContext.Provider>
			</div>
		</MenuContext.Provider>
	);
};

Layout.displayName = 'Layout';

Layout.propTypes = {
	className: PropTypes.string,
	debug: PropTypes.bool,
	auth: PropTypes.bool,
};

export default Layout;
