import React from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import BetaSign from '@asteria/component-beta-sign';
import Form, { Wrapper as FormWrapper, Input } from '@asteria/component-form';

import { TranslationService } from '@asteria/language';
import { getTheme } from '@asteria/utils-funcs/app';
import { cn } from '@asteria/utils-funcs/classes';

import { AuthContext } from '../context';
import Languages from '../languages';
import { defaultReducer, formatError } from '../utils';

const STEPS = ['COMPANY', 'SUCCESS'];

const ResetPage = React.memo((props) => {
	const { className } = props;

	const { onSubmit, partnerId, logo, languages, onLanguageChange, homepage } =
		React.useContext(AuthContext);

	const [search] = useSearchParams();
	const navigate = useNavigate();

	const [{ loading, error, step }, update] = React.useReducer(
		defaultReducer,
		{ loading: false, error: null, step: null, data: null },
	);

	const back = React.useCallback(() => {
		if (!step) {
			return navigate(
				['/auth/login', search.toString()].filter(Boolean).join('?'),
			);
		}

		const index = STEPS.indexOf(step);

		return update({
			type: 'NEXT',
			payload: { step: STEPS[index - 1] ?? null },
		});
	}, [navigate, search, step]);

	const next = React.useCallback(() => {
		return navigate(
			['/auth/login', search.toString()].filter(Boolean).join('?'),
		);
	}, [navigate, search]);

	const handleSubmit = React.useCallback(
		async (form) => {
			update({ type: 'START' });

			try {
				await onSubmit?.('auth:password-reset', form);
			} catch (err) {
				return update({
					type: 'FAILURE',
					payload: formatError({ error: err, form }),
				});
			}

			return update({ type: 'NEXT', payload: { step: 'SUCCESS' } });
		},
		[onSubmit],
	);

	const defaultValues = React.useMemo(
		() => ({ partnerId: partnerId, theme: getTheme() }),
		[partnerId],
	);

	return (
		<div
			className={cn(
				'asteria-view__auth-content',
				'asteria--variant-reset',
				className,
			)}
		>
			<BetaSign />
			<Form onSubmit={handleSubmit} defaultValues={defaultValues}>
				<Wrapper scroll>
					<Header logo={logo} onBack={back} verticalAlign="center">
						{TranslationService.get(
							[
								'auth.title',
								`auth.password.title`,
								`auth.password.forgot.title`,
								step === 'SUCCESS'
									? `auth.password.forgot.success.title`
									: null,
							].filter(Boolean),
						)}
					</Header>
					<Content scroll>
						{error ? (
							<Alert level="error">
								<Text>{error}</Text>
							</Alert>
						) : null}
						<FormWrapper scroll>
							<Content scroll>
								{step === 'SUCCESS' ? (
									<Alert
										title={TranslationService.get(
											'auth.password.forgot.success.alert.title',
										)}
										level="success"
									>
										<Text>
											{TranslationService.get(
												'auth.password.forgot.success.alert.content',
											)}
										</Text>
									</Alert>
								) : (
									<Input
										type="email"
										name="username"
										placeholder={TranslationService.get(
											'auth.password.forgot.email.placeholder',
										)}
										label={TranslationService.get(
											'auth.password.forgot.email.label',
										)}
										required
										autoFocus
									/>
								)}
							</Content>
						</FormWrapper>
					</Content>
					<Footer>
						<FooterSection position="first">
							<Button
								label={TranslationService.get([
									'action.back',
									`auth.password.action.back`,
									`auth.password.forgot.action.back`,
									`auth.password.forgot.success.action.back`,
								])}
								onClick={back}
								variant="secondary"
							/>
						</FooterSection>
						<FooterSection position="last">
							{step === 'SUCCESS' ? (
								<Button
									label={TranslationService.get([
										'action.submit',
										`auth.password.action.submit`,
										`auth.password.forgot.action.submit`,
										`auth.password.forgot.success.action.submit`,
									])}
									onClick={next}
									variant="primary"
								/>
							) : (
								<Button
									type="submit"
									label={TranslationService.get([
										'action.submit',
										`auth.password.action.submit`,
										`auth.password.forgot.action.submit`,
									])}
									variant="primary"
									loading={loading}
									disabled={loading}
								/>
							)}
						</FooterSection>
					</Footer>
				</Wrapper>
			</Form>

			{languages ? <Languages onChange={onLanguageChange} /> : null}

			{homepage ? (
				<Text
					size="sm"
					align="center"
					className="asteria-view__auth-homepage"
				>
					<span>
						{TranslationService.get(['auth.back'], 'Back to')}
					</span>
					<Button
						size="sm"
						label={TranslationService.get(
							['auth.back.link.label'],
							'www.asteria.ai',
						)}
						href={TranslationService.get(
							['auth.back.link.href'],
							'https://www.asteria.ai/',
						)}
						variant="link"
					/>
				</Text>
			) : null}
		</div>
	);
});

ResetPage.displayName = 'ResetPage';

ResetPage.propTypes = {
	className: PropTypes.string,
};

export default ResetPage;
