import React from 'react';

import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Alert from '@asteria/component-alert';
import BetaSign from '@asteria/component-beta-sign';
import Form, { Wrapper as FormWrapper, Input } from '@asteria/component-form';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { AuthContext } from '../context';
import Languages from '../languages';
import { useModalActions } from '../modal-collection';
import TermsAndConditionsModal from '../modals/terms';
import { defaultReducer, formatAuthResponse, formatError } from '../utils';

const STEPS = ['COMPANY', 'SUCCESS'];

const Step1 = React.memo(function Step1() {
	const { signupCode } = React.useContext(AuthContext);

	return [
		<Input
			key="username"
			type="email"
			name="user.username"
			label={TranslationService.get('signup.email.label')}
			placeholder={TranslationService.get('signup.email.placeholder')}
			required
			direction="vertical"
		/>,
		<Input
			key="password"
			type="password"
			name="user.password"
			label={TranslationService.get('signup.password.label')}
			placeholder={TranslationService.get('signup.password.placeholder')}
			required
			direction="vertical"
			autoFocus
		/>,
		<Input
			key="password2"
			type="password"
			name="user.passwordConfirm"
			label={TranslationService.get('signup.rePassword.label')}
			placeholder={TranslationService.get(
				'signup.rePassword.placeholder',
			)}
			required
			direction="vertical"
		/>,
		signupCode ? (
			<Input
				key="code"
				type="text"
				name="signupKey"
				label={TranslationService.get('signup.invitationCode.label')}
				placeholder={TranslationService.get(
					'signup.invitationCode.placeholder',
				)}
				required
				direction="vertical"
			/>
		) : null,
	];
});

const Step2 = React.memo(function Step2() {
	return [
		<Input
			key="name"
			type="text"
			name="company.name"
			label={TranslationService.get('signup.company.label')}
			placeholder={TranslationService.get('signup.company.placeholder')}
			required
			direction="vertical"
			autoFocus
		/>,
		<Input
			key="orgnumber"
			type="text"
			name="company.orgnumber"
			label={TranslationService.get('signup.organizationNumber.label')}
			placeholder={TranslationService.get(
				'signup.organizationNumber.placeholder',
			)}
			direction="vertical"
		/>,
		<Input
			key="firstName"
			type="text"
			name="user.firstName"
			label={TranslationService.get('signup.firstname.label')}
			placeholder={TranslationService.get('signup.firstname.placeholder')}
			direction="vertical"
		/>,
		<Input
			key="lastName"
			type="text"
			name="user.lastName"
			label={TranslationService.get('signup.lastname.label')}
			placeholder={TranslationService.get('signup.lastname.placeholder')}
			direction="vertical"
		/>,
	];
});

const SignupPageContent = React.memo(function SignupPageContent({
	error,
	step,
	loading,
	back,
	update,
}) {
	const { logo, onSubmit, signupCode } = React.useContext(AuthContext);

	const { trigger, getValues, setError } = useFormContext();
	const [search] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const next = React.useCallback(async () => {
		update?.({ type: 'START' });

		let valid = true;

		if (step === null) {
			valid = await trigger(
				[
					'user.username',
					'user.password',
					'user.passwordConfirm',
					signupCode ? 'signupKey' : null,
				].filter(Boolean),
			);

			if (valid) {
				const [password, password2] = getValues([
					'user.password',
					'user.passwordConfirm',
				]);

				if (password !== password2) {
					valid = false;

					setError('user.passwordConfirm', {
						type: 'error',
						message: TranslationService.get([
							'signup.password.error.mismatch',
							'auth.signup.password.error.mismatch',
						]),
					});
				}
			}

			if (valid && signupCode) {
				const code = getValues('signupKey');

				try {
					valid = await onSubmit?.('auth:code:validate', {
						key: code,
						partnerId: getValues('partnerId'),
					});
				} catch (err) {
					valid = false;
				}

				if (!valid) {
					setError('signupKey', {
						type: 'error',
						message: TranslationService.get([
							'signup.code.error.invalid',
							'auth.signup.code.error.invalid',
						]),
					});
				}
			}
		}

		if (valid) {
			const index = STEPS.indexOf(step);

			if (STEPS[index + 1]) {
				return update?.({
					type: 'NEXT',
					payload: { step: STEPS[index + 1] },
				});
			}

			dispatch(AppStore.setLoginStatus(true));

			return navigate(['/', search.toString()].filter(Boolean).join('?'));
		} else {
			update?.({ type: 'STOP' });
		}
	}, [
		dispatch,
		getValues,
		navigate,
		onSubmit,
		search,
		setError,
		signupCode,
		step,
		trigger,
		update,
	]);

	return (
		<Wrapper scroll>
			<Header logo={logo} onBack={back} verticalAlign="center">
				{TranslationService.get(['auth.title', `signup.title`])}
			</Header>
			<Content scroll>
				{error ? (
					<Alert level="error">
						<Text>{error}</Text>
					</Alert>
				) : null}

				<FormWrapper>
					<Content>
						{step === null ? <Step1 /> : null}
						{step === 'COMPANY' ? <Step2 /> : null}
						{step === 'SUCCESS' ? null : null}
					</Content>
				</FormWrapper>
			</Content>
			<Footer>
				<FooterSection position="first">
					<Button
						label={TranslationService.get([
							'action.back',
							`signup.action.back`,
						])}
						variant="secondary"
						onClick={back}
					/>
				</FooterSection>

				<FooterSection position="last">
					{step === null ? (
						<Button
							label={TranslationService.get([
								'action.next',
								`signup.action.next`,
							])}
							variant="primary"
							onClick={next}
							loading={loading}
							disabled={loading}
						/>
					) : null}

					{step === 'COMPANY' ? (
						<Button
							type="submit"
							label={TranslationService.get([
								'action.submit',
								`signup.action.submit`,
							])}
							variant="primary"
							loading={loading}
							disabled={loading}
						/>
					) : null}

					{step === 'SUCCESS' ? (
						<Button
							label={TranslationService.get([
								'action.next',
								`signup.action.next`,
							])}
							variant="primary"
							onClick={next}
							loading={loading}
							disabled={loading}
						/>
					) : null}
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

SignupPageContent.propTypes = {
	error: PropTypes.string,
	step: PropTypes.string,
	loading: PropTypes.bool,
	back: PropTypes.func,
	update: PropTypes.func,
};

const SignupPage = React.memo((props) => {
	const { className } = props;

	const {
		onAction,
		onSubmit,
		partnerId,
		languages,
		onLanguageChange,
		homepage,
	} = React.useContext(AuthContext);

	const { open } = useModalActions();

	const [search] = useSearchParams();
	const navigate = useNavigate();

	const [{ loading, error, step }, update] = React.useReducer(
		defaultReducer,
		{ loading: false, error: null, step: null, data: null },
	);

	const back = React.useCallback(() => {
		if (step === null) {
			return navigate(
				['/auth/login', search.toString()].filter(Boolean).join('?'),
			);
		}

		const index = STEPS.indexOf(step);

		return update({
			type: 'NEXT',
			payload: { step: STEPS[index - 1] ?? null },
		});
	}, [navigate, search, step]);

	const defaultValues = React.useMemo(
		() => ({ partnerId: partnerId }),
		[partnerId],
	);

	const handleSubmit = React.useCallback(
		async (form) => {
			if (form?.user.password !== form?.user.passwordConfirm) {
				return update({
					type: 'FAILURE',
					payload: TranslationService.get(
						'signup.password.error.mismatch',
					),
				});
			}

			delete form.user.passwordConfirm;

			update({ type: 'START' });

			let response;

			try {
				response = await onSubmit?.('auth:signup', form);
			} catch (err) {
				return update({
					type: 'FAILURE',
					payload: formatError({ error: err, form }),
				});
			}

			const { accessToken, refreshToken } = formatAuthResponse(response);

			onAction?.('auth:token', {
				accessToken,
				refreshToken,
				valid: true,
			});

			return navigate(['/', search.toString()].filter(Boolean).join('?'));
			// onAction?.('auth:token', { accessToken, refreshToken });

			// return update({ type: 'NEXT', payload: { step: 'SUCCESS' } });
		},
		[navigate, onAction, onSubmit, search],
	);

	const onTermsOpen = React.useCallback(() => {
		open(<TermsAndConditionsModal />);
	}, [open]);

	const onLoginButtonClick = React.useCallback(
		() =>
			navigate(
				['/auth/login', search.toString()].filter(Boolean).join('?'),
			),
		[navigate, search],
	);

	return (
		<div
			className={cn(
				'asteria-view__auth-content',
				'asteria--variant-signup',
				{ [`asteria--state-step-${step}`]: step },
				className,
			)}
		>
			<BetaSign />

			<Form defaultValues={defaultValues} onSubmit={handleSubmit}>
				<SignupPageContent
					error={error}
					loading={loading}
					step={step}
					back={back}
					update={update}
				/>
			</Form>

			{step === 'COMPANY' ? (
				<Text
					size="sm"
					align="center"
					className="asteria-view__auth-terms"
				>
					<span>
						{TranslationService.get([
							'signup.agree.terms',
							'auth.welcome.terms.label',
						])}
					</span>
					<Button
						size="sm"
						label={TranslationService.get([
							'signup.terms.button',
							'auth.welcome.terms.action',
						])}
						type="button"
						variant="link"
						onClick={onTermsOpen}
					/>
				</Text>
			) : null}

			<Text
				size="sm"
				align="center"
				className="asteria-view__auth-switcher"
			>
				<span>{TranslationService.get(['signup.login.label'])}</span>
				<Button
					size="sm"
					label={TranslationService.get(['signup.action.login'])}
					type="button"
					variant="link"
					onClick={onLoginButtonClick}
				/>
			</Text>

			{languages ? <Languages onChange={onLanguageChange} /> : null}

			{homepage ? (
				<Text
					size="sm"
					align="center"
					className="asteria-view__auth-homepage"
				>
					<span>
						{TranslationService.get(['auth.back'], 'Back to')}
					</span>
					<Button
						size="sm"
						label={TranslationService.get(
							['auth.back.link.label'],
							'www.asteria.ai',
						)}
						href={TranslationService.get(
							['auth.back.link.href'],
							'https://www.asteria.ai/',
						)}
						variant="link"
					/>
				</Text>
			) : null}
		</div>
	);
});

SignupPage.displayName = 'SignupPage';

SignupPage.propTypes = { className: PropTypes.string };

export default SignupPage;
