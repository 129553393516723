import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { formatISO, subYears } from 'date-fns';
import PropTypes from 'prop-types';

import { parseDate } from '@asteria/utils-funcs/normalize';

import ResponsiveContainer from '../../components/responsive-container';
import BasicCard, { Content } from '../basic';

import Extra from './v2/content';
import PromotionViewV2 from './v2/promotion';

import './styles.scss';

/**
 * @typedef ChildrenOptions
 * @property { boolean } loading
 * @property { 'paid' | 'today' | 'future' } variant
 * @property { import('@tanstack/react-query').UseQueryResult } query
 * @property { 'none' | 'erp' | 'bank' | 'both' } onboarding
 * @property { 'up' | 'down' } direction
 * @property { { postfix: unknown, data: unknown } } translationOptions
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 *
 * @typedef Props
 * @property { string } className
 * @property { string } startDate
 * @property { string } endDate
 * @property { React.ReactNode | (options: ChildrenOptions) => React.ReactNode } children
 * @property { Partial<{ as: React.ReactNode, props: unknown }> } [wrapper]
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onAction
 * @property { <TResponse = unknown>(action: string, data: unknown) => Promise<TResponse> } onSubmit
 */

function useRequest({ onSubmit, startDate, endDate, dataloader }) {
	return useQuery({
		queryKey: ['card', 'outgoing', { startDate, endDate }],
		queryFn: async ({ meta }) => {
			const response = await onSubmit?.('card:fetch', {
				type: 'outgoing',
				source: {
					startDate: startDate,
					endDate: endDate,
				},
				target: {
					startDate: formatISO(subYears(parseDate(startDate), 1), {
						representation: 'date',
					}),
					endDate: formatISO(subYears(parseDate(endDate), 1), {
						representation: 'date',
					}),
				},
				dataloader: meta?.dataloader,
			});

			return response;
		},

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		enabled: !!startDate && !!endDate,

		meta: { dataloader },
	});
}

/**
 * @typedef { import('../basic/types').Props } Props
 * @typedef { import('../basic/types').ChildrenProps } ChildrenProps
 */

/** @type { ChildrenProps['config'] } */
const CONFIG = {
	onboarding: { required: ['erp', 'bank'], optional: 'both' },
	card: {
		header: { trends: { v1: true } },
		content: {
			title: { trends: { v2: true } },
			default: {
				view: {
					v2: (props) => (
						<ResponsiveContainer
							className="asteria-component__card-chart-content"
							minWidth={640}
						>
							<Content.Title {...props} />
							<Extra {...props} />
							<Content.Text {...props} />
						</ResponsiveContainer>
					),
				},
			},
			promotion: {
				view: { v2: (props) => <PromotionViewV2 {...props} /> },
			},
			importing: {
				view: { v2: (props) => <PromotionViewV2 {...props} /> },
			},
			fetching: {
				view: { v2: (props) => <PromotionViewV2 {...props} /> },
			},
		},
	},
};

/** @type { React.FC<Props> } */
const OutgoingCard = React.memo(function OutgoingCard(props) {
	const { startDate, endDate, onSubmit, dataloader } = props;

	const query = useRequest({ onSubmit, startDate, endDate, dataloader });

	return (
		<BasicCard {...props} type="outgoing" config={CONFIG} query={query} />
	);
});

OutgoingCard.displayName = 'OutgoingCard';

OutgoingCard.propTypes = {
	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
	wrapper: PropTypes.shape({
		as: PropTypes.element,
		props: PropTypes.object,
	}),

	dataloader: PropTypes.object,
};

export default OutgoingCard;
