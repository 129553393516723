import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Footer, FooterSection } from '@asteria/component-core/wrapper';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';

import { useCompanyVersion } from '../../../CompanyVersion';
import { BatchRemoveButton } from '../../BatchRemoveModal';

import '../styles.scss';

const ReviewContentFooter = React.memo(function ReviewContentFooter(props) {
	const {
		onClose,
		onAction,
		onSubmit,
		batch,
		invoices,
		excludedInvoices,
		id,
	} = props;

	const dispatch = useDispatch();

	const version = useCompanyVersion();

	const batchHasError =
		invoices.some((object) => object?.errors?.length) ||
		batch?.status === 'ERROR';

	const onContinue = React.useCallback(
		(event) => {
			onClose?.(event);
			return onAction?.('go', `/invoices/batch/${id}`);
		},
		[id, onAction, onClose],
	);

	const onRetry = React.useCallback(
		async (event) => {
			onClose?.(event);

			if (version <= 1) {
				await onAction?.('invoices:batch:remove', { id: batch?.id });
			}

			dispatch(InvoiceStore.select([]));
			dispatch(InvoiceStore.select(excludedInvoices));

			return onAction?.('go', '/invoices/review');
		},
		[batch?.id, dispatch, excludedInvoices, onAction, onClose, version],
	);

	return (
		<Footer>
			<FooterSection>
				<Button
					variant="tertiary"
					label={TranslationService.get([
						'button.close',
						'action.close',
						'invoice.modal.review.close',
					])}
					onClick={onClose}
				/>
			</FooterSection>
			<FooterSection position="last">
				{batchHasError && version <= 1 ? (
					<BatchRemoveButton
						id={id}
						onAction={onAction}
						onSubmit={onSubmit}
						toggle={{
							variant: 'secondary',
							label: TranslationService.get([
								'button.remove',
								'action.remove',
								'invoice.modal.review.remove',
							]),
						}}
					/>
				) : null}

				{batchHasError ? (
					<Button
						variant="primary"
						label={TranslationService.get([
							'button.retry',
							'action.retry',
							'invoice.modal.review.retry',
						])}
						onClick={onRetry}
						disabled={!excludedInvoices?.length}
					/>
				) : null}

				{!batchHasError && version <= 1 ? (
					<Button
						variant="primary"
						label={TranslationService.get([
							'button.continue',
							'action.continue',
							'invoice.modal.review.continue',
						])}
						onClick={onContinue}
					/>
				) : null}
			</FooterSection>
		</Footer>
	);
});

ReviewContentFooter.propTypes = {
	id: PropTypes.string,
	batch: PropTypes.object,
	invoices: PropTypes.array,
	excludedInvoices: PropTypes.array,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default ReviewContentFooter;
