import React from 'react';

import { Route, Routes, matchPath, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

import Modal from '@asteria/component-modal';
import { useFeature } from '@asteria/component-tools/featureflag';

import { cn } from '@asteria/utils-funcs/classes';
import { useToggleState } from '@asteria/utils-funcs/state';

import { GuideContext } from './context';
import PageAgreement from './pages/agreement';
import PageConfiguration from './pages/configuration';
import PageConnect from './pages/connect';
import PageDetails from './pages/details';
import PageError from './pages/error';
import PageMissing from './pages/missing';
import PagePrinter from './pages/printer';
import PageStatus from './pages/status';
import PageSuccess from './pages/success';
import PageVismaAdministration from './pages/visma-administration';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { import('react').MouseEventHandler } onClose
 * @property { boolean } open
 */

const ROUTES = {
	CONNECT: '/onboarding/connect',
	MISSING: '/onboarding/missing',
	DETAILS: '/onboarding/:id',
	STATUS: '/onboarding/:id/status',
	SUCCESS: '/onboarding/:id/success',
	ERROR: '/onboarding/:id/error',
	AGREEMENT: '/onboarding/:type/:key',
	VISMA_ADMINISTRATION:
		'/onboarding/erp/visma.administration/configuration/*',
	PRINTER: '/onboarding/erp/printer/configuration/*',
	CONFIGURATION: '/onboarding/:type/:key/configuration',
};

/** @type { React.FC<Props> } */
const OnboardingGateway = React.memo(function OnboardingGateway(props) {
	const hasAgreementStep = useFeature('integration-agreement');

	return (
		<Routes>
			<Route path={ROUTES.CONNECT} element={<PageConnect {...props} />} />
			<Route path={ROUTES.MISSING} element={<PageMissing {...props} />} />
			<Route path={ROUTES.DETAILS} element={<PageDetails {...props} />} />
			<Route path={ROUTES.STATUS} element={<PageStatus {...props} />} />
			<Route path={ROUTES.SUCCESS} element={<PageSuccess {...props} />} />
			<Route path={ROUTES.ERROR} element={<PageError {...props} />} />

			<Route
				path={ROUTES.AGREEMENT}
				element={
					hasAgreementStep ? (
						<PageAgreement {...props} />
					) : (
						<PageConfiguration {...props} />
					)
				}
			/>

			<Route
				path={ROUTES.VISMA_ADMINISTRATION}
				element={<PageVismaAdministration {...props} />}
			/>

			<Route path={ROUTES.PRINTER} element={<PagePrinter {...props} />} />

			<Route
				path={ROUTES.CONFIGURATION}
				element={<PageConfiguration {...props} />}
			/>
		</Routes>
	);
});

OnboardingGateway.displayName = 'OnboardingGateway';

OnboardingGateway.propTypes = { className: PropTypes.string };

function useActiveRoute() {
	const location = useLocation();

	return Object.entries(ROUTES).find(([, route]) =>
		matchPath(route, location.pathname),
	)?.[0];
}

function getSize(route, guide) {
	switch (route) {
		case 'AGREEMENT':
		case 'MISSING':
		case 'ERROR':
			return 'sm';

		case 'VISMA_ADMINISTRATION':
		case 'PRINTER':
		case 'CONNECT':
		case 'DETAILS':
		case 'STATUS':
		case 'SUCCESS':
		case 'CONFIGURATION': {
			if (guide) {
				return 'lg';
			}

			return 'sm';
		}

		default:
			return 'md';
	}
}

function getPreviousURL(location) {
	if (location?.state?.backgroundLocation) {
		return getPreviousURL(location?.state?.backgroundLocation);
	}

	return location?.pathname;
}

/** @type { React.FC<Omit<Props, 'onClose'>> } */
const OnboardingGatewayModal = React.memo(function OnboardingModal(props) {
	const { open, onAction } = props;

	const location = useLocation();
	const active = useActiveRoute();

	const [guideState, guideActions] = useToggleState(
		['VISMA_ADMINISTRATION', 'PRINTER'].includes(active),
	);

	React.useEffect(() => {
		if (['VISMA_ADMINISTRATION', 'PRINTER'].includes(active)) {
			guideActions?.open();
		}

		if (['ERROR'].includes(active)) {
			guideActions?.close();
		}
	}, [active, guideActions]);

	const size = getSize(active, guideState);

	const close = React.useCallback(
		() =>
			onAction?.('go', {
				path: getPreviousURL(location) ?? '/onboarding',
			}),
		[location, onAction],
	);

	const ctx = React.useMemo(
		() => ({ state: guideState, toggle: guideActions.toggle }),
		[guideActions.toggle, guideState],
	);

	return (
		<GuideContext.Provider value={ctx}>
			<Modal
				className={cn('asteria-component__onboarding-modal', {
					'asteria--variant-guided': guideState,
				})}
				open={open}
				onClose={close}
				size={size}
			>
				<OnboardingGateway {...props} onClose={close} />
			</Modal>
		</GuideContext.Provider>
	);
});

OnboardingGatewayModal.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	open: PropTypes.bool,
};

export default OnboardingGateway;
export { OnboardingGatewayModal };
