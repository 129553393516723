import React from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { ConfirmationModal } from '@asteria/component-modal';

/**
 * @typedef Print
 * @property { string } id
 * @property { string } status
 * @property { string } createdAt
 *
 * @typedef Props
 * @property { Print } print
 * @property { boolean } open
 * @property { import('react').MouseEventHandler } onClose
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 */

/** @type { React.FC<Props> } */
const PrintIgnoreInvoicesModal = React.memo(function PrintIgnoreInvoicesModal(
	props,
) {
	const { open, print, onClose, onSubmit } = props;

	const id = print?._id ?? print?.id;
	const invoices = (print?.invoices ?? [])
		.filter((invoice) => !invoice?.discard?.length)
		.filter((invoice) => invoice?.errors?.length)
		.map((invoice) => invoice?._id ?? invoice?.id);

	const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: async () =>
			onSubmit?.('print:invoices:ignore', { id, invoices: invoices }),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				predicate: ({ queryKey }) =>
					queryKey.includes(id) || queryKey.includes('prints'),
			});

			return onClose();
		},
	});

	const options = React.useMemo(
		() => ({
			postfix: { from: 'print-invoices-ignore', status: print?.status },
			data: { id, data: print },
		}),
		[id, print],
	);

	return (
		<ConfirmationModal
			open={open}
			onClose={onClose}
			onSubmit={mutation.mutateAsync}
			options={options}
		/>
	);
});

PrintIgnoreInvoicesModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	print: PropTypes.object,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PrintIgnoreInvoicesModal;
