import React from 'react';

import { useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';

export function useInvoice({ id, onSubmit }) {
	const countries = useSelector(
		(store) => store?.invoices?.country?.candidates,
		(a, b) => isEqual(a, b),
	);

	const { data } = useQuery({
		queryKey: ['invoices', id],
		queryFn: async () =>
			onSubmit?.('invoices:details', { id, changes: true, countries }),

		placeholderData: null,
		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		keepPreviousData: true,
		enabled: !!id,
	});

	return data;
}

export function useInvoiceErrors({ errors, tabs }) {
	const calculateCount = React.useCallback(
		(key) => {
			const count =
				errors?.filter(({ path }) => {
					switch (key) {
						case 'details':
							return [
								'meta.invoiceNumber',
								'meta.clientNumber',
								'clientId',
								'dates.invoiceSent',
								'dates.invoiceDue',
								'isCreditInvoice',
							].includes(path);
						case 'delivery':
							return path?.startsWith('contact.shipping');
						case 'billing':
							return path?.startsWith('contact.billing');
						case 'contact':
							return path?.startsWith('contact.general');
						default:
							return false;
					}
				})?.length || 0;

			return count > 9 ? '9+' : count;
		},
		[errors],
	);

	const invoiceErrors = React.useMemo(
		() =>
			Object.keys(tabs).reduce(
				(acc, key) => ({
					...acc,
					[key]: calculateCount(key),
				}),
				{},
			),
		[tabs, calculateCount],
	);

	return invoiceErrors;
}
