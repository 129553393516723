import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Spinner from '@asteria/component-core/spinner';
import { Text, Title } from '@asteria/component-core/typography';

import {
	ActionBarSectionActions,
	Action as BaseAction,
} from '@asteria/component-actionbar';
import { IntegrationErrorTooltip } from '@asteria/component-integrations-v2';
import IntegrationCancelModal from '@asteria/component-integrations-v2/components/modal-cancel';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useToggleState } from '@asteria/utils-funcs/state';

import { useIntegration } from '../hooks';

const ActionIntegration = React.memo(function ActionIntegration(props) {
	const { id, type, onAction, onSubmit, data, className, onClick } = props;

	const [abortModalState, abortModalActions] = useToggleState(false);

	const { status, integration } = useIntegration({ type });

	const onRemove = React.useCallback(
		() => onSubmit?.('integrations:delete', integration),
		[integration, onSubmit],
	);

	return (
		<>
			<IntegrationCancelModal
				open={abortModalState}
				onClose={abortModalActions.close}
				id={integration?._id ?? integration?.id}
				execute={onRemove}
				onAction={onAction}
				onSubmit={onSubmit}
			/>
			<BaseAction
				variant="integration"
				onClick={onClick}
				className={className}
				title={
					<Title size="sm">
						{TranslationService.get(
							[
								'actions.action.title',
								`actions.action.${id}.title`,
								'actions.action.integration.title',
								`actions.action.integration.${id}.title`,
								`actions.action.integration.${status}.title`,
								`actions.action.integration.${status}.${id}.title`,
								integration?.type
									? [
											`actions.action.integration.${integration?.type}.title`,
											`actions.action.integration.${id}.${integration?.type}.title`,
											`actions.action.integration.${status}.${integration?.type}.title`,
											`actions.action.integration.${status}.${id}.${integration?.type}.title`,
									  ]
									: [],
								integration?.key
									? [
											`actions.action.integration.${integration?.key}.title`,
											`actions.action.integration.${id}.${integration?.key}.title`,
											`actions.action.integration.${status}.${integration?.key}.title`,
											`actions.action.integration.${status}.${id}.${integration?.key}.title`,
									  ]
									: [],
								integration?.type && integration?.key
									? [
											`actions.action.integration.${integration?.type}.${integration?.key}.title`,
											`actions.action.integration.${id}.${integration?.type}.${integration?.key}.title`,
											`actions.action.integration.${status}.${integration?.type}.${integration?.key}.title`,
											`actions.action.integration.${status}.${id}.${integration?.type}.${integration?.key}.title`,
									  ]
									: [],
							].flat(),
							undefined,
							{ ...data, integration: integration },
						)}
					</Title>
				}
				content={
					<Text>
						{TranslationService.get(
							[
								'actions.action.content',
								`actions.action.${id}.content`,
								'actions.action.integration.content',
								`actions.action.integration.${id}.content`,
								`actions.action.integration.${status}.content`,
								`actions.action.integration.${status}.${id}.content`,
								integration?.type
									? [
											`actions.action.integration.${integration?.type}.content`,
											`actions.action.integration.${id}.${integration?.type}.content`,
											`actions.action.integration.${status}.${integration?.type}.content`,
											`actions.action.integration.${status}.${id}.${integration?.type}.content`,
									  ]
									: [],
								integration?.key
									? [
											`actions.action.integration.${integration?.key}.content`,
											`actions.action.integration.${id}.${integration?.key}.content`,
											`actions.action.integration.${status}.${integration?.key}.content`,
											`actions.action.integration.${status}.${id}.${integration?.key}.content`,
									  ]
									: [],
								integration?.type && integration?.key
									? [
											`actions.action.integration.${integration?.type}.${integration?.key}.content`,
											`actions.action.integration.${id}.${integration?.type}.${integration?.key}.content`,
											`actions.action.integration.${status}.${integration?.type}.${integration?.key}.content`,
											`actions.action.integration.${status}.${id}.${integration?.type}.${integration?.key}.content`,
									  ]
									: [],
							].flat(),
							undefined,
							{ ...data, integration: integration },
						)}
					</Text>
				}
				actions={
					<ActionBarSectionActions
						className={cn({
							[`asteria-integration--status-${status}`]: status,
						})}
					>
						<Group
							flex
							horizontalAlign="center"
							verticalAlign="center"
						>
							{status === 'create' ? (
								<Button icon="plus" variant="primary" />
							) : null}
							{status === 'error'
								? [
										<Button
											key="help"
											icon="help"
											tooltip={
												<IntegrationErrorTooltip
													integration={integration}
												/>
											}
										/>,
								  ]
								: null}
							{status === 'importing'
								? [
										<Group
											flex
											verticalAlign="center"
											horizontalAlign="center"
											key="loading"
										>
											<Spinner />
											<Text size="xs">
												{TranslationService.get(
													[
														'actions.action.loading',
														`actions.action.${id}.loading`,
														'actions.action.integration.loading',
														`actions.action.integration.${id}.loading`,
													],
													undefined,
													{
														...data,
														integration:
															integration,
													},
												)}
											</Text>
										</Group>,
										<Button
											key="remove"
											icon="close"
											variant="tertiary"
											size="sm"
											onClick={abortModalActions.open}
										/>,
								  ]
								: null}
							{status === 'connected' ? (
								<Button icon="check" variant="primary" />
							) : null}
						</Group>
					</ActionBarSectionActions>
				}
			/>
		</>
	);
});

ActionIntegration.propTypes = {
	id: PropTypes.string,
	type: PropTypes.oneOf(['erp', 'bank']),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	data: PropTypes.object,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export default ActionIntegration;
