import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Form from '@asteria/component-form';

import { cn } from '@asteria/utils-funcs/classes';

import BaseCard from '../../base';
import Wrapper from '../../components/wrapper';
import {
	useCardPinning,
	useDateVariant,
	useOnboardingState,
	useTranslationData,
	useTrendDirection,
} from '../../hooks';

import * as actions from './actions';
import Content from './content';
import { ActionContext } from './context';
import FeedbackButtons from './feedback';
import Footer from './footer';
import Header from './header';
import {
	useChildren,
	useExperimentalHidden,
	useImporting,
	useState,
} from './hooks';
import { compare } from './utils';

import './styles.scss';

/**
 * @typedef { import('./types').Props } Props
 */

function CardDefault(props) {
	return (
		<>
			<Header {...props} />
			<Content {...props} />
			<Footer {...props} />
			<FeedbackButtons {...props} />
		</>
	);
}

/** @type { React.FC<Props> } */
const Card = React.memo(function Card({ children, ...props }) {
	const {
		className,

		type,
		query,

		startDate,
		endDate,

		onAction,
		onSubmit,

		extra,
		version = 1,
	} = props;

	const { state, dispatch } = useState(props);

	const isExperimentalHidden = useExperimentalHidden(props);
	const importing = useImporting(props);

	const pinned = useCardPinning({ type: type });
	const onboarding = useOnboardingState();

	const handleAction = React.useCallback(
		(...args) => {
			const [action, data] = args;

			if (action === 'card:dismiss') {
				// dispatch({
				// 	type: actions.constants.FEEDBACK_SET,
				// 	payload: {
				// 		visible: true,
				// 		step: 0,
				// 		variant: 'dismiss',
				// 	},
				// });

				return;
			}

			if (action === 'card:rate') {
				dispatch({
					type: actions.constants.FEEDBACK_SET,
					payload: {
						visible: true,
						rating: true,
						step: 0,
						variant: 'rate',
						state: data,
					},
				});

				return;
			}

			if (action === 'card:feedback:back') {
				dispatch({ type: actions.constants.FEEDBACK_BACK });
			}

			if (action === 'card:feedback:next') {
				dispatch({ type: actions.constants.FEEDBACK_NEXT });
			}

			if (action === 'card:feedback:close') {
				dispatch({ type: actions.constants.FEEDBACK_CLOSE });
			}

			if (action === 'card:promotion:set') {
				dispatch({
					type: actions.constants.PROMOTION_SET,
					payload: data,
				});
			}

			if (action === 'card:feedback:set') {
				dispatch({
					type: actions.constants.FEEDBACK_SET,
					payload: data,
				});
			}

			if (action === 'card:set') {
				dispatch({
					type: actions.constants.CARD_SET,
					payload: data,
				});
			}

			return onAction?.(...args);
		},
		[dispatch, onAction],
	);

	const submit = useMutation({
		mutationFn: async (form) => {
			return onSubmit?.('card:submit', form);
		},
		onSuccess: (response, form) => {
			if (form?.card?.state?.feedback?.visible) {
				return handleAction?.('card:feedback:next');
			}
		},
	});

	const handleSubmit = React.useCallback(
		(...args) => {
			const [action, data] = args;

			if (action === 'card:feedback:close') {
				return submit.mutateAsync(data);
			}

			return onSubmit?.(...args);
		},
		[onSubmit, submit],
	);

	const variant = useDateVariant({ startDate, endDate });
	const direction = useTrendDirection({
		card: type,
		type: variant,
		query: query,
	});

	const loading = query?.isFetching && !query?.isRefetching;

	const translationOptions = useTranslationData({
		type: type,

		query: query,
		trends: direction,

		past: variant === 'past',
		today: variant === 'today',
		future: variant === 'future',

		startDate,
		endDate,

		extra: extra,
		loading: loading,

		version,
	});

	const childrenProps = {
		...props,
		onAction: handleAction,
		onSubmit: handleSubmit,
		variant: variant,
		direction: direction,
		translationOptions: translationOptions,
		loading: loading,
		submitting: submit.isLoading,
		state: state,
		onboarding: onboarding,
		importing: importing,
		version: version,
	};

	const content = useChildren({ children, ...childrenProps }, CardDefault);

	const ctx = React.useMemo(
		() => ({ onAction: handleAction, onSubmit: handleSubmit }),
		[handleAction, handleSubmit],
	);

	const values = React.useMemo(
		() => ({
			card: { type, startDate, endDate, variant, state, onboarding },
			feedback: state?.feedback?.state,
			promotion: state?.promotion?.state,
		}),
		[endDate, onboarding, startDate, state, type, variant],
	);

	if (isExperimentalHidden) {
		return null;
	}

	const WrapperComponent = props?.wrapper?.as ?? Wrapper;

	return (
		<WrapperComponent {...props?.wrapper?.props} show={state.card.visible}>
			<ActionContext.Provider value={ctx}>
				<Form values={values} onSubmit={submit.mutate}>
					<BaseCard
						className={cn(
							'asteria--variant-basic',
							{ [`asteria--variant-${type}`]: type },
							{
								'asteria--state-pinned': pinned,
								'asteria--state-visible': state.card.visible,
								'asteria--state-promotion':
									state.promotion.visible,
								'asteria--state-feedback':
									state.feedback.visible,
							},
							className,
						)}
						compare={compare}
						loading={loading}
					>
						{content}
					</BaseCard>
				</Form>
			</ActionContext.Provider>
		</WrapperComponent>
	);
});

Card.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

	type: PropTypes.string,
	query: PropTypes.object,

	startDate: PropTypes.string,
	endDate: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	extra: PropTypes.object,

	wrapper: PropTypes.object,
	version: PropTypes.number,
};

export default Card;
export { Content, Footer, Header };
