import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { get } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import Skeleton from '@asteria/component-core/skeleton';
import Table, {
	TableCell,
	TableHeader,
	TableRow,
} from '@asteria/component-core/table';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { useToggleState } from '@asteria/utils-funcs/state';

import ServerError from '../../../server-error';
import PrintIgnoreInvoicesModal from '../ignore-invoices';
import PrintRemoveModal from '../remove';

import { DIRECTION } from './constants';
import PrintDetailsInfo from './info';
import Row from './row';
import Switcher from './switcher';
import { getSortingIcon, sort } from './utils';

import './styles.scss';

/**
 * @typedef Props
 * @property { string } className
 * @property { string } id
 * @property { (action: string, data: unknown) => Promise<unknown> } onSubmit
 * @property { (action: string, data: unknown) => Promise<unknown> } onAction
 * @property { import('react').MouseEventHandler } onClose
 * @property { boolean } open
 */

/** @type { React.FC<Props> } */
const PrintDetails = React.memo(function PrintDetails(props) {
	const { className, id, onSubmit, onAction, onClose } = props;

	const [ignoreInvoicesModalState, ignoreInvoicesModalActions] =
		useToggleState(false);

	const [removeModalState, removeModalActions] = useToggleState(false);

	const onRemoveSuccess = React.useCallback(
		(event) => {
			removeModalActions?.close?.(event);
			return onClose?.(event);
		},
		[onClose, removeModalActions],
	);

	const [sorting, setSorting] = React.useState(null);
	const [active, setActive] = React.useState(null);

	const sortBy = React.useCallback((key) => {
		setSorting((current) => {
			if (!current || current?.key !== key) {
				return { key, direction: DIRECTION.DESC };
			}

			if (current.direction === DIRECTION.ASC) {
				return null;
			}

			return { key, direction: DIRECTION.ASC };
		});
	}, []);

	const query = useQuery({
		queryKey: ['print', id],
		queryFn: async () =>
			onSubmit?.('invoice-layout:details', {
				id,
				fields: `
					_id id createdAt updatedAt status pdfUri
					errors { code message }
					meta { pages }
					invoices {
						_id id createdAt updatedAt
						meta { invoiceNumber clientNumber }
						sums { original { total currency } }
						errors { code message path }
						discard: services(filters: { status: ["DISCARD"] }) {
							id serviceId status createdAt updatedAt
							invoices { invoiceId status errors }
						}
					}
				`,
			}),

		select: (response) => ({
			...response,
			invoices: (response?.invoices ?? []).map((invoice) => ({
				...invoice,
				error: invoice?.errors?.[0],
			})),
		}),

		refetchOnMount: true,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});

	const translationData = React.useMemo(
		() => ({ id, data: query.data }),
		[id, query.data],
	);

	const translationOptions = React.useMemo(
		() => ({
			postfix: { status: query?.data?.status },
			data: translationData,
		}),
		[query?.data?.status, translationData],
	);

	const invoices = React.useMemo(() => {
		let response = [...(query?.data?.invoices ?? [])];

		if (active === 'ERROR') {
			response = response.filter((invoice) => invoice?.errors?.length);
		}

		if (active === 'IGNORED') {
			response = response.filter((invoice) => invoice?.discard?.length);
		} else {
			response = response.filter((invoice) => !invoice?.discard?.length);
		}

		if (sorting?.key) {
			response.sort((a, b) => {
				let source = get(a, sorting?.key);
				let target = get(b, sorting?.key);

				if (sorting?.key === 'createdAt') {
					source = parseISO(source);
					target = parseISO(target);
				}

				return sort(source, target, sorting?.direction);
			});
		}

		return response;
	}, [active, query?.data?.invoices, sorting?.direction, sorting?.key]);

	const status = query?.data?.status;
	const printErrors = query?.data?.errors ?? [];

	const isErrorStatus = status === 'ERROR';
	const isInvoiceError = (query?.data?.invoices ?? [])
		.filter((invoice) => !invoice?.discard?.length)
		.every((invoice) => invoice?.errors?.length);

	const isError = isErrorStatus && isInvoiceError;
	const isWarning = isErrorStatus && !isInvoiceError;

	const errors = printErrors.concat((query?.data?.invoices ?? [])
		.filter((invoice) => !invoice?.discard?.length)
		.flatMap((invoice) => invoice?.errors ?? []));

	const ignored = (query?.data?.invoices ?? []).filter(
		(invoice) => invoice?.discard?.length,
	);

	return (
		<>
			<PrintIgnoreInvoicesModal
				open={ignoreInvoicesModalState}
				onClose={ignoreInvoicesModalActions.close}
				onAction={onAction}
				onSubmit={onSubmit}
				print={query?.data}
			/>
			<PrintRemoveModal
				open={removeModalState}
				print={query?.data}
				onClose={removeModalActions.close}
				onSuccess={onRemoveSuccess}
				onAction={onAction}
				onSubmit={onSubmit}
			/>
			<Wrapper
				className={cn(
					'asteria-component__print-details',
					{ 'asteria--state-error': isError },
					{ 'asteria--state-warning': isWarning },
					className,
				)}
				scroll
			>
				<Header onClose={onClose}>
					{TranslationService.getV2(
						['print.details.title'],
						translationOptions,
					)}
				</Header>
				<Content
					className={cn(
						'gap-4',
						'asteria-component__print-details-content',
					)}
				>
					<ServerError
						type="printer"
						errors={errors}
						onAction={onAction}
						onSubmit={onSubmit}
						extra={{ print: query?.data }}
						variant={isError ? 'error' : 'warning'}
					/>

					<PrintDetailsInfo
						query={query}
						translationOptions={translationOptions}
						onAction={onAction}
						onSubmit={onSubmit}
						status={
							isError ? 'error' : isWarning ? 'warning' : null
						}
					/>

					{errors.length || ignored.length ? (
						<Switcher
							active={active}
							onChange={setActive}
							query={query}
							translationOptions={translationOptions}
						/>
					) : null}

					{invoices.length ? (
						<Table className="asteria-component__print-details-table">
							<TableHeader>
								<TableCell
									className={cn('asteria--variant-id', {
										'asteria--state-active':
											sorting?.key === 'id',
									})}
								>
									<Button
										label={TranslationService.getV2(
											[
												'print.details.table.header.action',
											],
											{
												postfix: {
													type: 'id',
													...translationOptions.postfix,
												},
												data: translationOptions.data,
											},
										)}
										onClick={() => sortBy('id')}
										icon="chevron-down"
										iconActive={getSortingIcon(sorting)}
										active={sorting?.key === 'id'}
										iconPosition="last"
									/>
								</TableCell>
								<TableCell
									className={cn('asteria--variant-created', {
										'asteria--state-active':
											sorting?.key === 'createdAt',
									})}
								>
									<Button
										label={TranslationService.getV2(
											[
												'print.details.table.header.action',
											],
											{
												postfix: {
													type: 'createdAt',
													...translationOptions.postfix,
												},
												data: translationOptions.data,
											},
										)}
										onClick={() => sortBy('createdAt')}
										icon="chevron-down"
										iconActive={getSortingIcon(sorting)}
										active={sorting?.key === 'createdAt'}
										iconPosition="last"
									/>
								</TableCell>
								<TableCell
									className={cn('asteria--variant-number', {
										'asteria--state-active':
											sorting?.key ===
											'meta.invoiceNumber',
									})}
								>
									<Button
										label={TranslationService.getV2(
											[
												'print.details.table.header.action',
											],
											{
												postfix: {
													type: 'number',
													...translationOptions.postfix,
												},
												data: translationOptions.data,
											},
										)}
										onClick={() =>
											sortBy('meta.invoiceNumber')
										}
										icon="chevron-down"
										iconActive={getSortingIcon(sorting)}
										active={
											sorting?.key ===
											'meta.invoiceNumber'
										}
										iconPosition="last"
									/>
								</TableCell>
								<TableCell
									className={cn('asteria--variant-message', {
										'asteria--state-active':
											sorting?.key === 'error.message',
									})}
								>
									<Button
										label={TranslationService.getV2(
											[
												'print.details.table.header.action',
											],
											{
												postfix: {
													type: 'message',
													...translationOptions.postfix,
												},
												data: translationOptions.data,
											},
										)}
										onClick={() => sortBy('error.message')}
										icon="chevron-down"
										iconActive={getSortingIcon(sorting)}
										active={
											sorting?.key === 'error.message'
										}
										iconPosition="last"
									/>
								</TableCell>
								<TableCell className="asteria--variant-actions" />
							</TableHeader>
							{query.isFetching && !query.isRefetching
								? Array.from({ length: 5 }).map((_, index) => (
										<TableRow key={index}>
											<TableCell className="asteria--variant-id">
												<Skeleton />
											</TableCell>
											<TableCell className="asteria--variant-created">
												<Skeleton />
											</TableCell>
											<TableCell className="asteria--variant-number">
												<Skeleton />
											</TableCell>
											<TableCell className="asteria--variant-message">
												<Skeleton />
											</TableCell>
											<TableCell className="asteria--variant-actions">
												<Skeleton />
											</TableCell>
										</TableRow>
								  ))
								: null}
							{invoices.map((invoice) => (
								<Row
									key={invoice?._id ?? invoice?.id}
									invoice={invoice}
									print={query?.data}
									onAction={onAction}
									onSubmit={onSubmit}
									translationOptions={translationOptions}
								/>
							))}
						</Table>
					) : (
						<TextGroup className="p-4 bg-alert-success-normal-background">
							<div className="flex gap-2 items-center justify-center">
								<div
									className={cn(
										'asteria-component__alert-icon',
										'asteria--status-success',
									)}
								>
									<Icon icon="document" size="sm" />
								</div>
								<Translation
									translationKey="print.details.table.empty.title"
									Component={Title}
									className="text-content-page-title"
									align="center"
								/>
							</div>
							<Translation
								translationKey="print.details.table.empty.content"
								Component={Text}
								align="center"
							/>
						</TextGroup>
					)}
				</Content>
				<Footer>
					<FooterSection position="first">
						<Button
							variant="tertiary"
							label={TranslationService.getV2(
								['print.details.action'],
								{
									postfix: {
										type: 'close',
										...translationOptions.postfix,
									},
									data: translationOptions.data,
								},
							)}
							onClick={onClose}
						/>
					</FooterSection>
					{isError || isWarning ? (
						<FooterSection position="last">
							{isError ? (
								<Button
									variant="secondary"
									label={TranslationService.getV2(
										['print.details.action'],
										{
											postfix: {
												type: 'remove',
												...translationOptions.postfix,
											},
											data: translationOptions.data,
										},
									)}
									onClick={removeModalActions.open}
								/>
							) : null}
							{isWarning ? (
								<Button
									variant="secondary"
									label={TranslationService.getV2(
										['print.details.action'],
										{
											postfix: {
												type: 'ignore',
												...translationOptions.postfix,
											},
											data: translationOptions.data,
										},
									)}
									onClick={ignoreInvoicesModalActions.open}
								/>
							) : null}
						</FooterSection>
					) : null}
				</Footer>
			</Wrapper>
		</>
	);
});

PrintDetails.displayName = 'PrintDetails';

PrintDetails.propTypes = {
	className: PropTypes.string,

	id: PropTypes.string,

	open: PropTypes.bool,
	onClose: PropTypes.func,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

/** @type { React.FC<Props> } */
const PrintDetailsModal = React.memo(function PrintDetailsModal(props) {
	const { open, onClose } = props;

	return (
		<Modal
			className={cn(
				'asteria-component__print-modal',
				'asteria--variant-details',
			)}
			open={open}
			onClose={onClose}
		>
			<PrintDetails {...props} />
		</Modal>
	);
});

PrintDetailsModal.propTypes = {
	className: PropTypes.string,

	id: PropTypes.string,

	open: PropTypes.bool,
	onClose: PropTypes.func,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default PrintDetails;
export { PrintDetailsModal };
