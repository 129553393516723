import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Icon from '@asteria/component-core/icon';
import { Circle } from '@asteria/component-core/progress';

import { TranslationService } from '@asteria/language';
import { useToggleState } from '@asteria/utils-funcs/state';

import IntegrationCancelModal from '../../../modal-cancel';

import Wrapper from './wrapper';

/** @type { React.FC<{ status: string, id: string, onAction: () => void, onSubmit: () => void }> } */
const Status = React.memo(function Status(props) {
	const { status, id, size, onAction, onSubmit } = props;

	const [abortModalState, abortModalActions] = useToggleState(false);

	const cancel = useMutation({
		mutationFn: async () => onSubmit?.('integrations:delete', { id: id }),
	});

	if (!status) {
		return null;
	}

	if (status === 'connected') {
		return (
			<Wrapper {...props}>
				<div className="bg-alert-success-icon-normal-background p-2 rounded-full inline-flex">
					<Icon
						size={size === 'xs' ? 'sm' : undefined}
						icon="check"
					/>
				</div>
			</Wrapper>
		);
	}

	if (status === 'error') {
		return (
			<Wrapper {...props}>
				<div className="bg-alert-error-icon-normal-background p-2 rounded-full inline-flex">
					<Icon
						size={size === 'xs' ? 'sm' : undefined}
						icon="warning"
					/>
				</div>
			</Wrapper>
		);
	}

	if (status === 'outdated') {
		return (
			<Wrapper {...props}>
				<div className="bg-alert-warning-icon-normal-background p-2 rounded-full inline-flex">
					<Icon
						size={size === 'xs' ? 'sm' : undefined}
						icon="warning"
					/>
				</div>
			</Wrapper>
		);
	}

	if (status === 'disabled') {
		return (
			<Wrapper {...props}>
				<div className="bg-alert-warning-icon-normal-background p-2 rounded-full inline-flex">
					<Icon
						size={size === 'xs' ? 'sm' : undefined}
						icon="warning"
					/>
				</div>
			</Wrapper>
		);
	}

	if (status === 'importing' || status === 'initiating') {
		return (
			<>
				<IntegrationCancelModal
					open={abortModalState}
					onClose={abortModalActions.close}
					id={id}
					onAction={onAction}
					onSubmit={onSubmit}
					execute={cancel.mutateAsync}
				/>
				<Wrapper {...props}>
					<div className="flex items-center gap-2">
						<Circle progress={-1} />
						<Button
							variant="link"
							label={TranslationService.get([
								'button.cancel',
								'action.cancel',
							])}
							onClick={abortModalActions.open}
							size="sm"
						/>
					</div>
				</Wrapper>
			</>
		);
	}

	return null;
});

Status.propTypes = {
	id: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	status: PropTypes.string,
	size: PropTypes.string,
};

export default Status;
