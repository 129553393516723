import React from 'react';

import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import { ConfirmationModal } from '@asteria/component-modal';

/**
 * @typedef Props
 * @property { string } className
 * @property { (action: string, data?: unknown) => unknown } onAction
 * @property { (action: string, data?: unknown) => unknown } onSubmit
 * @property { boolean } open
 * @property { import('react').MouseEventHandler } onClose
 */

/** @type { React.FC<Props}> } */
const RemovePrintDialog = React.memo(function RemovePrintDialog(props) {
	const { open, onClose, onSubmit } = props;

	const agree = useMutation({
		mutationFn: async () => onSubmit?.('print:dialog:close'),
		onSuccess: (data, variables) => onClose(variables),
	});

	const options = React.useMemo(
		() => ({ postfix: { from: 'print-dialog-close' } }),
		[],
	);

	return (
		<ConfirmationModal
			open={open}
			onClose={onClose}
			onSubmit={agree.mutateAsync}
			options={options}
		/>
	);
});

RemovePrintDialog.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,

	className: PropTypes.string,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default RemovePrintDialog;
